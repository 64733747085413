import { LOGIN_ACTION, Action, GET_LOGIN_ACTION, LOGOUT_ACTION } from "../Actions/types";

export default function accountReducer(state = { login: false }, action: Action) {
    const { type, payload } = action;
    switch (type) {
        case LOGIN_ACTION: {
            // Login action does not change anything
            return { ...state, login: payload };
        }
        case GET_LOGIN_ACTION: {
            return { ...state, ...payload };
        }
        case LOGOUT_ACTION: {
            return { login: false };
        }
        default:
            return state;
    }
}

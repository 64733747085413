import React from "react";
import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { ContactInner } from "./Contact";

export default function About() {
    return (
        <Template hideDummyHeader theme="dark">
            <div id="about-page-holder">
                <Section id="about-hangzhou" full style={{ height: 480 }} containerStyle={{ backgroundImage: "url('/assets/about/hangzhou.jpg')" }} />
                <Section className="about-section">
                    <h1>Our Mission</h1>
                    <p>
                        ‘We identify for our customers the global trends aiming to find out the main opportunities, assuring our best commitment to turn them
                        into their expected products.’
                    </p>
                </Section>
                <Section className="about-section">
                    <h1>Our DNA</h1>
                    <p>
                        TRUMODE is based on almost twenty years’ experience of a group of fashion professionals, <br />
                        composed by a range of Chinese and European designers in a showroom of 1500 square meters, <br />
                        flexible and informal, in the city center of Hangzhou, China. <br />
                        We are an active part within our customers’ chain of production.
                    </p>
                    <p>
                        The company was founded by Thomas. A couple of years later Ray a French designer joint him. <br />
                        Since then, they start a long partnership working continuously side by side. <br />
                        Surrounded by an efficient and loyal team. <br />
                        Today our company supplies several brands in many markets such as France, Spain, Italy, Portugal, UK, Poland, Israel, Turkey, USA,
                        Canada.
                    </p>
                </Section>
                <Section className="about-section">
                    <h1>What Do We Think</h1>
                    <p>
                        ‘We can’t always explain where an idea come from. <br />
                        It’s the result of a wink, an image or a furtive lived memory. <br />
                        But the quality and the credibility of ideas, rest on four pillars which are imagination, innovation, creativity and vision. <br />
                        And we believe that communication and anticipation can only be the keys of a successful partnerships.’
                    </p>
                </Section>
                <Section className="about-section">
                    <h1>Services</h1>
                    <p>
                        ‘We supply all needed support and services allowing to develop a perfect product, <br />
                        from the origin to the final production. <br />
                        We are always flanked by our customers during the whole production run, taking care of all the process involved in creativity,
                        preparation and production.’
                    </p>
                </Section>
                <Section className="about-section">
                    <h1>Our Skills</h1>
                    <p>
                        ‘Specialized in flat knit from gage 1,5/16 and circular knit. <br />
                        Supported by a strong design team, efficient seniors’ merchandiser and with its own factory and eighteen subcontractors, Trumode reach a
                        monthly capacity of around 400 000 pieces.’ 
                    </p>
                </Section>
                <Section className="about-section" style={{ maxWidth: "1200px" }}>
                    <h1>Extract of Our Customer List</h1>
                    <img src="/assets/about/customers.jpg" alt="Trumode Customers" />
                    <p style={{ width: "100%", textAlign: "right" }}>And More...</p>
                </Section>
                <Section className="about-section" style={{ maxWidth: "1200px" }}>
                    <h1>Leading Team</h1>
                    <img src="/assets/about/Slide4.jpeg" alt="slide 4" />
                </Section>
                <Section className="about-section" style={{ maxWidth: "1600px" }}>
                    <img src="/assets/about/Slide5.jpeg" alt="slide 5" />
                </Section>
                <Section padding className="about-contact-section" containerStyle={{ backgroundImage: "url('/assets/about/contact_bg.jpg')" }}>
                    <ContactInner />
                </Section>
            </div>
        </Template>
    );
}

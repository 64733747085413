import React from "react";
import Template from "../../Template";
import { Section } from "../../Components/Section";
import "./index.scss";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";
import { FullLoginPage } from "../../Components/FullLoginPage";

export default function InNow() {
    const history = useHistory();

    const account = useSelector((state: RootState) => state.account);

    if (!account.user) {
        return <FullLoginPage />;
    }

    return (
        <Template>
            <Section full>
                <div id="in-now-cover">
                    <div
                        className="in-now-cover-part bg-container"
                        style={{ backgroundImage: "url('/assets/images/in-now-women-3.png')" }}
                    >
                        <div className="in-now-overlay v-flex as">
                            <div className="in-now-title">WOMEN</div>
                            <div className="in-now-button-group">
                                <button onClick={() => history.push("/in-now/in_women_knitwear_3")}>Knitwear</button>
                                <button onClick={() => history.push("/in-now/in_women_circular_3")}>Circular</button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="in-now-cover-part bg-container"
                        style={{ backgroundImage: "url('/assets/images/in-now-men-3.png')" }}
                    >
                        <div className="in-now-overlay v-flex ae">
                            <div className="in-now-title">MEN</div>
                            <div className="in-now-button-group">
                                <button onClick={() => history.push("/in-now/in_men_knitwear_3")}>Knitwear</button>
                                <button onClick={() => history.push("/in-now/in_men_circular_3")}>Circular</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section full containerStyle={{ position: "fixed", zIndex: 200, bottom: 0 }}>
                <div id="in-now-banner">
                    Have a walk in the{" "}
                    <span style={{ color: "rgb(184, 104, 77)", fontWeight: "bold" }}>Best Sellers</span> in Asia, China
                    ( including HK ), Korea, Japan, etc.
                </div>
            </Section>
        </Template>
    );
}

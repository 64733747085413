import React from "react";
import { BaseWidgetProps, BaseWdigetEditorProps } from "..";
import Carousol, { CarousolSlide } from "../../Carousol";
import { oss } from "../../../utils";
import { useHistory } from "react-router";

import Form, { IFieldProps } from "../../FinalForm";
import Schema from "./carousol_widget.schema.json";
import DropFile from "../../FinalForm/DropFile";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

export interface CarousolWidgetProps extends BaseWidgetProps {
    slides?: CarousolWidgetSlide[];
    oss_style?: string;
    oss_style_preview?: string;
    pagination?: boolean;
    navigation?: boolean;
    loop?: boolean;
    autoplay?: boolean;
    speed?: number;
    autoHeight?: boolean;
    lazy?: boolean;
}

export interface CarousolWidgetSlide {
    image?: string;
    link?: string;
    oss_style?: string;
    oss_style_preview?: string;
    style?: React.CSSProperties;
}

export default function CarousolWidget(props: CarousolWidgetProps) {
    const history = useHistory();

    const renderSlides = () => {
        const renderedSlides = [];
        if (!props.slides || props.slides.length === 0) {
            return <CarousolSlide>No Slides</CarousolSlide>;
        }
        for (let i = 0; i < props.slides.length; i++) {
            const slide = props.slides[i];
            renderedSlides.push(
                <CarousolSlide
                    key={slide.image}
                    background={oss(slide.image || "", { style: slide.oss_style_preview || props.oss_style_preview || "full_carousol_desk_preview" })}
                    backgroundSet={oss(slide.image || "", { style: slide.oss_style || props.oss_style || "full_carousol_desk" })}
                    onClick={slide.link ? () => slideOnClick(slide.link) : undefined}
                    style={slide.style}
                    lazy={props.lazy}
                ></CarousolSlide>
            );
        }
        return renderedSlides;
    };

    const slideOnClick = (link?: string) => {
        if (link) {
            history.push(link);
        }
    };

    return (
        <Carousol
            name={props.name}
            config={{
                pagination: props.pagination
                    ? {
                          el: ".swiper-pagination",
                          clickable: true,
                          renderBullet: (index: number, className: string) => `<span class=${className}></span>`,
                      }
                    : undefined,
                navigation: props.navigation
                    ? {
                          nextEl: ".swiper-button-next",
                          prevEl: ".swiper-button-prev",
                      }
                    : undefined,
                autoplay: props.autoplay,
                speed: props.speed,
                loop: props.loop,
                autoHeight: props.autoHeight,

                // Disable preloading of all images
                preloadImages: true,
                // Enable lazy loading
                lazy: false,
            }}
            style={props.style}
        >
            {renderSlides()}
        </Carousol>
    );
}

interface SlideProps {
    image: string;
    link?: string;
    style?: React.CSSProperties;
}

export function CarousolWidgetEditor(props: BaseWdigetEditorProps) {
    const [newFileSet, setNewFileSet] = React.useState<Set<string>>(new Set());
    const [state, setState] = props.context;

    const renderSlides = (fieldProps?: IFieldProps) => {
        if (!props.context || !props.context[0] || !fieldProps) return null;

        const slides = props.context[0].slides;

        const renderedSlides = [];
        for (const slide of slides) {
            renderedSlides.push(
                <div>
                    <div>{slide.image}</div>
                    <div>{slide.link}</div>
                </div>
            );
        }

        const addFiles = (fieldProps: IFieldProps, files: FileList) => {
            for (const file of Array.from(files)) {
                if (!state["$files"]) state["$files"] = { [fieldProps.name]: [] };
                state["$files"][fieldProps.name].push(file);

                const fullFilename = (fieldProps.field["#prefix"] || "") + file.name;
                if (!state[fieldProps.name]) state[fieldProps.name] = [];
                state[fieldProps.name].push({ image: fullFilename });
                newFileSet.add(fullFilename);
            }
            setState({ ...state });
            setNewFileSet(newFileSet);
        };

        const SlideList = SortableContainer(({ items }: { items: SlideProps[] }) => {
            if (!items || items.length === 0) return <div className="empty-file-list">拖拽文件到这里</div>;
            return (
                <div style={{ width: "100%" }}>
                    {items.map((item, index) => (
                        <FileContainer key={`item-${item.image}`} index={index} item={item} />
                    ))}
                </div>
            );
        });

        const openFile = (e: React.MouseEvent, file: string) => {
            e.preventDefault();
            e.stopPropagation();
            if (isNew(file)) {
                alert("暂不支持预览新文件");
            } else {
                const url = oss(file as string, { style: "full_carousol_desk" });
                window.open(url, "_blank");
            }
        };

        const isNew = (item: string): boolean => {
            return newFileSet.has(item);
        };

        const getLabel = (item: string): string => {
            if (isNew(item)) {
                return `${item} - 新文件 (等待上传)`;
            } else {
                return item;
            }
        };

        const deleteFile = (e: React.MouseEvent, filename: string) => {
            e.stopPropagation();
            if (newFileSet.has(filename)) {
                const newFiles = state["$files"][fieldProps.name];
                for (let i = 0; i < newFiles.length; i++) {
                    if (newFiles[i].name === filename) {
                        newFiles.splice(i, 1);
                        break;
                    }
                }
                const oldFiles = state[fieldProps.name];
                for (let i = 0; i < oldFiles.length; i++) {
                    if (oldFiles[i].image === filename) {
                        oldFiles.splice(i, 1);
                        break;
                    }
                }
                setState({ ...state });

                newFileSet.delete(filename);
                setNewFileSet(newFileSet);
            } else {
                const oldFiles = state[fieldProps.name];
                for (let i = 0; i < oldFiles.length; i++) {
                    if (oldFiles[i].image === filename) {
                        oldFiles.splice(i, 1);
                        break;
                    }
                }

                if (!state["$files_delete"]) state["$files_delete"] = [];
                state["$files_delete"].push(filename);
                setState({ ...state });
            }
        };

        const updateLink = (item: SlideProps, e: React.ChangeEvent<HTMLInputElement>) => {
            // if (!state[fieldProps.name]) return;
            for (const slide of state[fieldProps.name]) {
                if (slide.image === item.image) {
                    slide.link = e.target.value;
                }
            }
            setState({ ...state });
        };

        const FileContainer = SortableElement(({ item }: { item: SlideProps }) => (
            <div className={`file-holder ${isNew(item.image) ? "new-file" : ""}`} onDoubleClick={(e) => openFile(e, item.image)}>
                <div className="file-info">
                    <div className="h-flex js ac">
                        <div className="svg-holder">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#191919">
                                <path
                                    d="M13.172,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8.828c0-0.53-0.211-1.039-0.586-1.414l-4.828-4.828 C14.211,2.211,13.702,2,13.172,2z M18.5,9H13V3.5L18.5,9z"
                                    fill="#191919"
                                />
                            </svg>
                        </div>
                        <span>{getLabel(item.image)}</span>
                    </div>
                    <div className="inner-field-holder">
                        <input
                            name="link"
                            value={item.link}
                            onChange={(e) => updateLink(item, e)}
                            placeholder="黏贴需要连接到的相对地址, 如'trends/<trend_name>’或‘samples/<po_number>’"
                        ></input>
                    </div>
                    <div className="css-holder">{item.style ? "Style:" + item.style : null}</div>
                </div>
                <div className="file-actions">
                    <button className="circle sm" onClick={(e) => deleteFile(e, item.image)}>
                        ×
                    </button>
                </div>
            </div>
        ));

        const onSortStart = () => {
            document.body.style.cursor = "grabbing";
        };
        const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
            document.body.style.cursor = "unset";
            arrayMove(state[fieldProps.name], oldIndex, newIndex);
            setState({ ...state });
        };

        const arrayMove = (array: any[], oldIndex: number, newIndex: number) => {
            const temp = array[oldIndex];
            const diff = oldIndex > newIndex ? -1 : 1;
            for (let i = oldIndex; i !== newIndex; i += diff) {
                array[i] = array[i + diff];
            }
            array[newIndex] = temp;
        };

        return (
            <DropFile className="form-drop" handleDrop={(fileList) => addFiles(fieldProps, fileList)}>
                <SlideList items={state[fieldProps.name]} onSortStart={onSortStart} onSortEnd={onSortEnd} pressDelay={100} helperClass="grabbing" />
            </DropFile>
        );
    };

    return (
        <Form
            id="carousol_widget_editor"
            schema={Schema}
            context={props.context}
            actions={{ submit: props.submit }}
            customized={{
                slides: (props) => renderSlides(props.fieldProps),
            }}
        />
    );
}

import React, { useEffect } from "react";
import MainMenu from "./MainMenu";
import MultiLevelMenu, { GetMenuByPath, IMenuItem } from "../MultiLevelMenu";

interface HeaderMenuProps {
    itemHoverCallback?: Function;
}

export default function HeaderMenu(props: HeaderMenuProps) {
    const hover = (menuItem: IMenuItem | null) => {
        if (props.itemHoverCallback) props.itemHoverCallback(menuItem);
    };

    useEffect(() => {
        for (let i of MainMenu.submenu) {
            registerHoverListener(true, i.name);
        }
        return function cleanup() {
            for (let i of MainMenu.submenu) {
                registerHoverListener(false, i.name);
            }
        };
    });

    const registerHoverListener = (register: boolean, topItem: string) => {
        let menuItem = document.getElementById(`header-menu-${topItem}`);
        if (register)
            menuItem?.addEventListener("mouseover", () => {
                hover(GetMenuByPath(MainMenu, topItem));
            });
        else
            menuItem?.removeEventListener("mouseover", () => {
                hover(GetMenuByPath(MainMenu, topItem));
            });
    };

    return <MultiLevelMenu menu={MainMenu} deep={1} baseClass="header-menu" name="header-menu" />;
}

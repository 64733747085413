import React, { useEffect, useState } from "react";
import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { RouteComponentProps, useHistory } from "react-router";
import { post, SERVER_STATUS } from "../../utils";
import Form, { FormResultStatus, IFormResult } from "../../Components/FinalForm";
import UserEnrollSchema from "../../Schemas/user_enroll.schema.json";
import { Link } from "react-router-dom";

interface IEnrollProps {
    id: string;
}

interface IEnrollData {
    _id: string;
    username: string;
    email: string;
    accessId: string;
}

export default function Enroll(props: RouteComponentProps<IEnrollProps>) {
    const [originalData, setOriginalData] = useState<IEnrollData | SERVER_STATUS.NOT_FOUND | SERVER_STATUS.NOT_START>(SERVER_STATUS.NOT_START);
    const [formData, setFormData] = useState({});
    const history = useHistory();

    useEffect(() => {
        post("accounts/enroll", {
            id: props.match.params.id,
        }).then(
            (res) => {
                if (res.data.data) {
                    setOriginalData(res.data.data);
                } else {
                    setOriginalData(SERVER_STATUS.NOT_FOUND);
                }
            },
            (err) => {
                if (err) {
                    Promise.resolve(err);
                    setOriginalData(SERVER_STATUS.NOT_FOUND);
                }
            }
        );
    }, [props.match.params.id]);

    if (originalData === SERVER_STATUS.NOT_START) {
        return (
            <Template>
                <Section padding full>
                    <h1>Loading</h1>
                </Section>
            </Template>
        );
    } else if (originalData === SERVER_STATUS.NOT_FOUND) {
        return (
            <Template>
                <Section padding full>
                    <h1>Enrollment ID Not found</h1>
                    <p>This is because this link has already used, or it's an outdated, or invalid link.</p>
                    <p>
                        If you need help, please <Link to="/contact">contact us</Link>
                    </p>
                </Section>
            </Template>
        );
    }

    const submit = async () => {
        let returnRes: IFormResult = {};
        await post("accounts/enroll-submit", {
            originalData,
            formData,
        }).then(
            (res) => {
                if (res.status === 200) {
                    returnRes = {
                        status: FormResultStatus.SUCCESS,
                        message: "Registration complete, Please remember your password and keep it safe. We will redirect you to home page in 3 secs.",
                        clearInput: true,
                    };
                    setTimeout(() => {
                        history.push("/");
                    }, 3000);
                } else {
                    returnRes = {
                        status: FormResultStatus.FAILURE,
                        message: "Registration failed, please try again later.",
                    };
                }
            },
            (err) => {
                console.error(err);
                returnRes = {
                    status: FormResultStatus.FAILURE,
                    message: "Registration failed, please try again later.",
                };
            }
        );
        return returnRes;
    };

    return (
        <Template>
            <Section padding containerStyle={{ background: "#eee" }}>
                <h1>Welcome To Trumode</h1>
                <p>We are glad you choose to trust Trumode! Please take a minute to finish the registration process and create your account.</p>
            </Section>
            <Section padding form>
                <h3>Your username is</h3>
                <p>{originalData.username}</p>
                <Form
                    id="user_enroll"
                    schema={UserEnrollSchema}
                    context={[formData, setFormData]}
                    actions={{
                        submit,
                    }}
                />
                <p className="caption">Use 8 or more characters with a mix of lowercase letters, uppercase letters and numbers</p>
            </Section>
        </Template>
    );
}

import React from "react";
import Template from "../Template";
import { Section } from "./Section";
import LoginModal from "./Header/LoginModal";

export function FullLoginPage() {
    return (
        <Template>
            <Section padding containerStyle={{ background: "rgba(0,0,0,0.1)" }}>
                <h1>Please Sign In to view</h1>
                <p>This page will refresh after you signing in</p>
            </Section>
            <Section padding>
                <div style={{ width: 600, maxWidth: "100%" }}>
                    <LoginModal id="full-login-modal" close={() => {}} inline />
                </div>
            </Section>
        </Template>
    );
}

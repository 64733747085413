import React from "react";
import "./index.scss";
import Dragon from "./dragon";
import MultiLevelMenu from "../MultiLevelMenu";
import MainMenu from "../Header/MainMenu";

export default function Footer() {
    return (
        <footer className="tr-container" id="footer-container">
            <div id="footer">
                <MultiLevelMenu name="footer-menu" menu={MainMenu} deep={2} />
                <div id="footer-copyright">Copyright © {new Date().getFullYear()} Hangzhou Trumode Fashion Co.,Ltd. All rights Reserved.</div>
                <div id="footer-dragon">
                    <Dragon />
                </div>
            </div>
            <div id="footer-background-dragon">
                <Dragon />
            </div>
        </footer>
    );
}

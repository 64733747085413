import React, { useState, useEffect, CSSProperties } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";

import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { post, SearchQuery, keyToLabel, getClientWidth } from "../../utils";
import { FullLoginPage } from "../../Components/FullLoginPage";
import { Showcase } from "../../Components/Showcase";
import { ShowcaseItem, FilterWithOptions, FirstFilterChangeEvent, Option } from "../../Components/Showcase/types";

export interface Trend {
    _id: string;
    name: string;
    title: string;
    year?: string;
    season?: string;
    cat?: string;
    subtitle?: string;
    cover_image?: string;
    tags?: string[];
    time_create?: string;
    time_modify?: string;

    slides_style?: CSSProperties | undefined;
}

export interface TrendShowcaseItem extends ShowcaseItem {}

export default function Trends() {
    const { query } = useParams();
    const [searchQuery, setSearchQuery] = useState<SearchQuery>();
    const [searching, setSearching] = useState<boolean>(false);
    const [data, setData] = useState<TrendShowcaseItem[]>([]);
    const [tags, setTags] = useState<{ key: string; freq: number }[]>([]);
    const history = useHistory();

    const [, setAddingNew] = useState<boolean>(false);
    const [, setEditingItem] = useState<ShowcaseItem | null>(null);

    const account = useSelector((state: RootState) => state.account);

    const isMobile = getClientWidth() < 600;

    useEffect(() => {
        if (!account.user) return;
        try {
            const searchQuery = new SearchQuery(query);
            setSearchQuery(searchQuery);
        } catch (e) {
            console.error("Invalid Search Query: ", query);
            // TODO: Better Error Handling
            // history.push(" "); // Redirect to empty query
        }
    }, [query, history, account.user]);

    useEffect(() => {
        if (!searchQuery) return;
        setSearching(true);
        post("trends/search", {
            query: searchQuery.getQueries()
        }).then(
            (res) => {
                setSearching(false);
                const trends = res.data as Trend[];
                if (!trends || trends.length === 0) return;

                // Construct Trends Data
                const trendShowcaseItems: TrendShowcaseItem[] = [];
                trends.forEach((trend) => {
                    const dateString = trend.time_create ? new Date(trend.time_create).toLocaleDateString() : undefined;
                    trendShowcaseItems.push({
                        _id: trend._id,
                        images: trend.cover_image ? [trend.cover_image] : undefined,
                        cat: trend.cat,
                        name: trend.name,
                        title: trend.title,
                        tags: trend.tags,
                        time_create: trend.time_create,
                        time_update: trend.time_modify,
                        cardProps: {
                            title: trend.title,
                            titleRight: keyToLabel(trend.cat),
                            subtitle: trend.subtitle,
                            secondLineExtra: dateString
                        }
                    });
                });
                setData(trendShowcaseItems);
            },
            (err) => {
                setSearching(false);
                console.warn(err.response.data);
                // TODO: Better Error Handling
                Promise.resolve(err);
            }
        );
    }, [query, searchQuery]);

    // Collect all tags
    useEffect(() => {
        if (!data || data.length === 0) return;
        const tagMap: { [key: string]: { key: string; freq: number } } = {};
        for (const item of data) {
            if (item.tags && item.tags.length > 0) {
                for (const tag of item.tags) {
                    if (tagMap[tag]) tagMap[tag].freq += 1;
                    else tagMap[tag] = { key: tag, freq: 1 };
                }
            }
        }
        const tagsArray = Object.values(tagMap);
        tagsArray.sort((a, b) => b.freq - a.freq);
        setTags(tagsArray);
    }, [data]);

    const getFirstFilters = (): FilterWithOptions[] => {
        const q = searchQuery && searchQuery.getQueries() ? searchQuery.getQueries() : null;
        let firstFileters: FilterWithOptions[] = [];
        firstFileters = [
            {
                key: "cat",
                options: constructOptions(["women", "men"]),
                value: q ? q["cat"] : []
            }
        ];
        return firstFileters;
    };

    const constructOptions = (keys: string[]): Option[] => {
        const res: Option[] = [];
        keys.forEach((key) => res.push({ key }));
        return res;
    };

    const filterOnChange = ({ event, key, option }: FirstFilterChangeEvent) => {
        if (!searchQuery) return;
        const query = searchQuery.getQueries();

        if (!query[key]) {
            query[key] = [];
        }

        // Multiple first filter solution

        // const index = query[key].indexOf(option);
        // if (index !== -1) {
        //     query[key].splice(index, 1);
        //     if (query[key].length === 0) delete query[key];
        // } else if (!query[key]) {
        //     query[key] = [option];
        // } else {
        //     query[key].push(option);
        // }

        // Single first filter solution
        const index = query[key].indexOf(option);
        if (index === -1) query[key] = [option];
        else query[key] = [];

        history.replace(searchQuery.toString() || " ");
    };

    const getSecondFilters = (): FilterWithOptions[] => {
        const secondFiltersValues = searchQuery?.getSecondFilters() || {};
        return [
            {
                key: "tags",
                options: tags,
                value: secondFiltersValues["tags"] ? secondFiltersValues["tags"][0] : null
            }
        ];
    };

    // Access Control
    // if (!account.user && !localStorage.getItem("accessToken")) {
    //     return <FullLoginPage />;
    // }

    return (
        <Template>
            <Section full frame padding>
                <Showcase
                    id="trend-showcase"
                    title="Trends"
                    resourceType="trends"
                    items={data}
                    searching={searching}
                    firstFilters={getFirstFilters()}
                    secondFilters={getSecondFilters()}
                    firstFilterOnChange={filterOnChange}
                    onAddNew={() => setAddingNew(true)}
                    onItemClick={(item) => history.push(`/trends/${item.name}`)}
                    onItemEdit={(item) => setEditingItem(item)}
                    gridConfig={{
                        columns: isMobile ? 1 : 3,
                        imageRatio: 0.66
                    }}
                    searchQueryFields={["name", "title", "tags"]}
                    searchQueryThreshold={0.2}
                />
            </Section>
        </Template>
    );
}

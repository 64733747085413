import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Reducers";

import { ShowcaseProps, ShowcaseItem, GridConfig, FilterWithOptions } from "./types";
import { ShowcaseGrid } from "./ShowcaseGrid";
import { R, keyToLabel, getClientWidth } from "../../utils";
import "./index.scss";
import "./index.m.scss";
import Select from "react-select";
import { ScrollToTopButton } from "../ScrollToTopButton";
import Fuse from "fuse.js";
import { getSavedItemAction } from "../../Actions";
import Modal from "../Modal";

export function Showcase(props: ShowcaseProps) {
    const isMobile = getClientWidth() < 600;

    // States
    const [gridConfig, setGridConfig] = useState<GridConfig>({ columns: 3, imageRatio: 1, ...props.gridConfig });
    const [filterModal, setFilterModal] = useState(false);

    // Redux Store
    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);
    const preference = useSelector((state: RootState) => state.preference);

    // Second Filters
    const [secondFilters, setSecondFilters] = useState<FilterWithOptions[]>([]);
    const [filteredItems, setFilteredItems] = useState<string[]>([]);

    // Special Filters
    const [showNewAdded, setShowNewAdded] = useState<boolean>(false);
    const [newAddedResult, setNewAddedResult] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [showFavorites, setShowFavorites] = useState<boolean>(false);

    const [searchQueryResult, setSearchQueryResult] = useState<ShowcaseItem[] | null>(null);

    // final Result
    const [finalResult, setFinalResult] = useState<ShowcaseItem[]>([]);

    // Set Second Filters
    useEffect(() => {
        const _localSecondFilters: FilterWithOptions[] = [];
        let setFilterFlag = secondFilters.length !== props.secondFilters.length;
        for (let i = 0; i < props.secondFilters.length; i++) {
            const filter = props.secondFilters[i];
            if (
                !setFilterFlag &&
                (!secondFilters[i] ||
                    filter.key !== secondFilters[i].key ||
                    JSON.stringify(filter.options) !== JSON.stringify(secondFilters[i].options))
            ) {
                setFilterFlag = true;
            }
            const { key, options, value } = filter;
            _localSecondFilters.push({ key, options, value });
        }
        if (setFilterFlag) setSecondFilters(_localSecondFilters);
    }, [props.secondFilters, secondFilters]);

    // General Second Filters
    useEffect(() => {
        const _filteredItems: string[] = [];
        // TODO: Disgusting logics...
        props.items.forEach((item) => {
            for (const filter of secondFilters) {
                if (filter.value && item[filter.key] && item[filter.key].indexOf(filter.value) === -1) {
                    if (filter.key === "content") {
                        let flag = false;
                        for (const content of item["content"]) {
                            if ((content[0] as string).indexOf(filter.value as string) !== -1) {
                                flag = true;
                                break;
                            }
                        }
                        if (!flag) return;
                    } else return;
                } else if (filter.value && !item[filter.key]) {
                    return;
                }
            }
            _filteredItems.push(item._id);
        });
        setFilteredItems(_filteredItems);
    }, [props.items, secondFilters]);

    // New added filter
    useEffect(() => {
        if (showNewAdded) {
            props.items.sort((a, b) => new Date(b.time_create || 0).getTime() - new Date(a.time_create || 0).getTime());
            let last = null;
            const currentBunch: string[] = [];
            for (const item of props.items) {
                const current = new Date(item.time_create || 0).getTime() / 1000;
                if (!last || last - current < 86400) {
                    currentBunch.push(item._id);
                    last = current;
                } else break;
            }
            setNewAddedResult(currentBunch);
        } else {
            setNewAddedResult([]);
        }
    }, [props.items, showNewAdded]);

    // Favorites filter
    useEffect(() => {
        if (!preference.savedItems) dispatch(getSavedItemAction());
    }, [dispatch, preference.savedItems, props.items, props.resourceType]);

    // Search Query filter
    useEffect(() => {
        if (searchQuery.length > 0) {
            const fuse = new Fuse(props.items, {
                keys: props.searchQueryFields,
                threshold: props.searchQueryThreshold
            });
            const fuseResults = fuse.search(searchQuery);
            const result = fuseResults.map((fuseResult) => fuseResult.item);
            setSearchQueryResult(result);
        } else {
            setSearchQueryResult(null);
        }
    }, [props.items, props.searchQueryFields, props.searchQueryThreshold, searchQuery]);

    // Calculate Final Filtered Result (This is the most headache one...)
    useEffect(() => {
        let result: ShowcaseItem[] = [];

        // Process Search Querying...
        if (searchQueryResult !== null) {
            result = [...searchQueryResult];
        } else {
            result = [...props.items];
        }

        // Filtering Favorites Items
        const favoritesSet = new Set(preference.savedItems ? preference.savedItems[props.resourceType] : []);
        result.forEach((item) => {
            item.isFavorite = false;
            if (favoritesSet.has(item._id)) {
                item.isFavorite = true;
            }
        });
        if (showFavorites) {
            result = result.filter((item) => favoritesSet.has(item._id));
        }

        // Filtering New Added Items
        const newAddedSet = new Set(newAddedResult);
        if (showNewAdded) {
            result = result.filter((item) => newAddedSet.has(item._id));
        }

        // Filtering Second filters
        const secondFilterSet = new Set(filteredItems || []);
        result = result.filter((item) => secondFilterSet.has(item._id));

        // Sortable
        result.sort((a, b) => {
            // If both items have no create date, use po desc compare
            if (!a.time_create && !b.time_create) return b.po.localeCompare(a.po);
            // If ome item has no create date, it will always be lower
            if (!a.time_create) return 1;
            if (!b.time_create) return -1;
            // Normal compare using the create date.
            return new Date(b.time_create || 0).getTime() - new Date(a.time_create || 0).getTime();
        });

        setFinalResult(result);
    }, [
        filteredItems,
        newAddedResult,
        searchQueryResult,
        showFavorites,
        props.items,
        showNewAdded,
        preference.savedItems,
        props.resourceType
    ]);

    // Renderes ------------------------------------------------------------------------------------------------------------------------------------------------------------------

    const renderSidebar = () => {
        if (props.hideSidebar) return null;
        return (
            <div className="showcase-sidebar">
                <h1>{props.title}</h1>
                {renderSearchBar()}
                {renderFirstFilters()}
            </div>
        );
    };

    const renderSearchBar = () => {
        return (
            <div className="showcase-sidebar-filter-container h-flex ac">
                <input
                    name="search"
                    placeholder="Search..."
                    className={searchQuery.length > 0 ? "active" : ""}
                    value={searchQuery || ""}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ transition: "200ms" }}
                />
                <button
                    className={`input-clear-btn ${searchQuery.length > 0 && "show"}`}
                    onClick={() => setSearchQuery("")}
                >
                    ×
                </button>
            </div>
        );
    };

    const renderFirstFilters = () => {
        const renderedFirstFilters: React.ReactNodeArray = [];

        // New Added
        renderedFirstFilters.push(
            <div key="new-add" className="showcase-sidebar-filter-container">
                <li
                    id="filter-new"
                    className={showNewAdded ? "active" : ""}
                    key="new_added"
                    onClick={() => setShowNewAdded(!showNewAdded)}
                >
                    New Added
                </li>
            </div>
        );

        // Saved
        if (account.user && account.user.role !== "guest") {
            renderedFirstFilters.push(
                <div key="favorites" className="showcase-sidebar-filter-container">
                    <li
                        id="filter-favorites"
                        className={showFavorites ? "active" : ""}
                        key="favorites"
                        onClick={() => setShowFavorites(!showFavorites)}
                    >
                        <div className="h-flex js ac">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                <path d="M15.765,2.434l2.875,8.512l8.983,0.104c0.773,0.009,1.093,0.994,0.473,1.455l-7.207,5.364l2.677,8.576 c0.23,0.738-0.607,1.346-1.238,0.899L15,22.147l-7.329,5.196c-0.63,0.447-1.468-0.162-1.238-0.899l2.677-8.576l-7.207-5.364 c-0.62-0.461-0.3-1.446,0.473-1.455l8.983-0.104l2.875-8.512C14.482,1.701,15.518,1.701,15.765,2.434z" />
                            </svg>
                            Saved {props.title}
                        </div>
                    </li>
                </div>
            );
        }

        props.firstFilters.forEach((filter) => {
            const renderedFilterOptions: React.ReactNodeArray = [];
            filter.options.forEach((_option) => {
                const option = _option.key;
                const active = filter.value && filter.value.indexOf(option) !== -1;
                const translator = R(filter.key, filter.key);
                const label = translator && translator[option] ? translator[option] : keyToLabel(option);
                renderedFilterOptions.push(
                    <li
                        className={active ? "active" : undefined}
                        key={`${filter.key}-${option}`}
                        onClick={(e) => {
                            // Reset second filters
                            secondFilters.forEach((secondFilter) => {
                                secondFilter.value = "";
                            });
                            setSecondFilters(secondFilters);
                            props.firstFilterOnChange({ event: e, key: filter.key, option });
                        }}
                    >
                        {label}
                    </li>
                );
            });
            renderedFirstFilters.push(
                <div key={filter.key} className="showcase-sidebar-filter-container mt3">
                    {filter.key !== "root" && <h2>{R()[filter.key]?.label_en || keyToLabel(filter.key)}</h2>}
                    {renderedFilterOptions}
                </div>
            );
        });
        return renderedFirstFilters;
    };

    const renderToolbar = () => {
        return (
            <div className="showcase-toolbar h-flex ac jb">
                {renderSecondFilters()}
                {renderHelpers()}
            </div>
        );
    };

    const renderSecondFilters = () => {
        const renderedSecondFilters: React.ReactNodeArray = [];

        for (let i = 0; i < secondFilters.length; i++) {
            const filter = secondFilters[i];
            const filterLabel = R() && R()[filter.key] ? R()[filter.key].label_en : keyToLabel(filter.key);
            const filterOptionsMap: { [key: string]: { value: string | null; label: string; freq?: number } } = {};

            const getOptions = () => {
                const filterOptions: { value: string | null; label: string }[] = [];

                filterOptions.push({ value: "", label: `All ${filterLabel}` });
                filterOptionsMap[""] = { value: "", label: `All ${filterLabel}`, freq: props.items.length };

                filter.options.forEach((_option) => {
                    const option = _option.key;
                    const lable =
                        R(filter.key, filter.key) && R(filter.key, filter.key)[option]
                            ? R(filter.key, filter.key)[option].label_en
                            : keyToLabel(option);
                    const selectOption = { value: option, label: lable, freq: _option.freq };
                    filterOptions.push(selectOption);
                    filterOptionsMap[option] = { ...selectOption, freq: _option.freq };
                });
                return filterOptions;
            };

            renderedSecondFilters.push(
                <div key={filter.key}>
                    <Select
                        id={`${filter.key}-input`}
                        className="input-field-item showcase-second-filters-form"
                        classNamePrefix="ifi"
                        name={filter.key}
                        options={getOptions()}
                        placeholder={`Filter by ${filterLabel}`}
                        value={filterOptionsMap[filter.value as string]}
                        onChange={(value) => {
                            if (value as { value: string; label: string }) {
                                const newValue = value as { value: string; label: string };
                                filter.value = newValue.value;
                                setSecondFilters([...secondFilters]);
                            }
                        }}
                        formatOptionLabel={(option) => (
                            <div className="h-flex ac jb">
                                <div>{option.label}</div>
                                {option.value !== null && filterOptionsMap[option.value].freq && (
                                    <div className="tag-counter capsule h-flex ac jc">
                                        {filterOptionsMap[option.value].freq}
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </div>
            );
        }

        return <div className="showcase-second-filters h-flex ac js">{renderedSecondFilters}</div>;
    };

    const renderHelpers = () => {
        const renderAdminTools = () => {
            if (!account.user || account.user.role !== "admin") {
                return null;
            }

            return (
                <>
                    <button className="toolbar" onClick={() => props.onAddNew()}>
                        Add New
                    </button>
                </>
            );
        };

        return (
            <div className="showcase-helpers h-flex ac je">
                <div className="item-number-indicator">
                    {finalResult.length} {finalResult.length > 1 ? "items" : "item"}
                </div>
                <div className="layout-buttons buttons">
                    <button
                        className={`toolbar ${gridConfig.columns === 3 ? "active" : ""}`}
                        onClick={() => setGridConfig({ ...gridConfig, columns: 3 })}
                    >
                        3 columns
                    </button>
                    <button
                        className={`toolbar ${gridConfig.columns === 4 ? "active" : ""}`}
                        onClick={() => setGridConfig({ ...gridConfig, columns: 4 })}
                    >
                        4 columns
                    </button>
                </div>
                {renderAdminTools()}
            </div>
        );
    };

    // Mobile Only
    const renderFilterFab = () => {
        return (
            <>
                <div
                    className={"fab"}
                    onClick={() => {
                        setFilterModal(true);
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#191919">
                        <path d="M 3 3 L 3 5 L 21 5 L 21 3 L 3 3 z M 5 7 L 10 13 L 10 21 L 14 21 L 14 13 L 19 7 L 5 7 z" />
                    </svg>
                    FILTERS
                </div>
                {filterModal && renderFilterModal()}
            </>
        );
    };

    const renderFilterModal = () => {
        return (
            <Modal
                id={`${props.title}-filters`}
                backdrop
                backdropClose
                escClose
                titleBar={`${props.title} Filters`}
                close={() => setFilterModal(false)}
            >
                {renderSearchBar()}
                {renderFirstFilters()}
                <hr />
                {renderSecondFilters()}
            </Modal>
        );
    };

    const classList = ["showcase-container"];
    if (props.hideSidebar) classList.push("no-sidebar");

    return (
        <div id={props.id} className={classList.join(" ")}>
            {!isMobile && renderSidebar()}
            <div id="showcase-main" className={["showcase-main", props.searching ? "searching" : ""].join(" ")}>
                {!isMobile && renderToolbar()}
                <ShowcaseGrid
                    items={finalResult}
                    gridConfig={gridConfig}
                    onItemClick={props.onItemClick}
                    onItemEdit={props.onItemEdit}
                />
            </div>
            <div className="fab-container">
                {isMobile && renderFilterFab()}
                <ScrollToTopButton />
            </div>
        </div>
    );
}

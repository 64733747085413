import React from "react";
import ReactDOM from "react-dom";

interface IPortalProps {
    container: string;
    children: React.ReactNode;
}

export default function Portal(props: IPortalProps) {
    const el = document.querySelector(props.container);
    if (el) {
        return ReactDOM.createPortal(props.children, el);
    }
    return null;
}

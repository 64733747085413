import React, { useRef, useState } from "react";

interface IDropFileProps {
    handleDrag?: Function;
    handleDragIn?: Function;
    handleDragOut?: Function;
    handleDropRaw?: Function;
    handleDrop?: (files: FileList) => any;
    clickToSelect?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    draggingStyle?: React.CSSProperties;
    isMulti?: boolean;
    children?: React.ReactNode;
    className?: string;
}

export default function DropFile(props: IDropFileProps) {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [dragConter, setDragConter] = useState<number>(0);

    const handleDrag = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (props.handleDrag) props.handleDrag(e);
    };

    const handleDragIn = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragConter(dragConter + 1);
        if (props.handleDragIn) props.handleDragIn(e);
    };

    const handleDragOut = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragConter(dragConter - 1);
        if (props.handleDragOut) props.handleDragOut(e);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setDragConter(0);
        if (props.handleDropRaw) props.handleDropRaw(e);
        else if (props.handleDrop && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            props.handleDrop(e.dataTransfer.files);
            e.dataTransfer.clearData();
        }
    };

    const handleClick = () => {
        if (!props.clickToSelect) return;
        if (inputRef && inputRef.current) inputRef.current.click();
    };

    const classList = props.className ? [...props.className.split(" ")] : [];
    let concatStyle = { ...props.style };
    if (props.disabled) classList.push("disabled");
    else {
        if (props.clickToSelect) classList.push("drop-file-clickable");
        if (dragConter > 0) {
            classList.push("drop-file-dragging");
            concatStyle = { ...concatStyle, ...props.draggingStyle };
        }
    }

    return (
        <div
            className={classList.join(" ")}
            style={concatStyle}
            onClick={handleClick}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <input
                ref={inputRef}
                type="file"
                style={{ display: "none" }}
                multiple={props.isMulti}
                disabled={props.disabled}
                onInput={(e) => {
                    const files = e.currentTarget ? e.currentTarget.files : null;
                    if (props.handleDrop && files) {
                        props.handleDrop(files);
                    }
                }}
            />
            {props.children}
        </div>
    );
}

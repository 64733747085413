import React, { useEffect } from "react";
import { ShowcaseGridProps } from "./types";
import { WindowScroller, Grid } from "react-virtualized";
import { ShowcaseCard } from "./ShowcaseCard";
import { getClientWidth } from "../../utils";

export function ShowcaseGrid(props: ShowcaseGridProps) {
    // Resize Rerender
    const [width, setWidth] = React.useState<number>(0);

    useEffect(() => {
        const handler = () => {
            const showcaseMain = document.getElementById("showcase-main");
            if (showcaseMain) {
                const showCaseWidth = (showcaseMain as HTMLElement).offsetWidth;
                setWidth(showCaseWidth);
            }
        };
        handler();

        window.addEventListener("resize", handler);
        return () => {
            window.removeEventListener("resize", handler);
        };
    }, []);

    const gridCellRenderer = (columnIndex: number, rowIndex: number, columnWidth: number, rowHeight: number) => {
        const { columns } = props.gridConfig;
        const index = columns * rowIndex + columnIndex;
        if (index >= props.items.length) return;
        const item = props.items[index];

        const style: React.CSSProperties = {
            position: "absolute",
            width: columnWidth,
            height: rowHeight,
            left: columnWidth * columnIndex,
            top: rowHeight * rowIndex,
        };

        return (
            <div key={item._id} className="showcase-card-container" style={{ ...style }}>
                <ShowcaseCard item={item} onItemClick={props.onItemClick} onItemEdit={props.onItemEdit} {...item.cardProps} />
            </div>
        );
    };

    const columnCount = props.gridConfig.columns;
    const columnWidth = (getClientWidth() < 600 ? width : width - 64) / columnCount;
    const rowHeight = (columnWidth - 16) * props.gridConfig.imageRatio + 60 + 16;

    return (
        <WindowScroller>
            {({ height, scrollTop }) => {
                return (
                    <Grid
                        width={width}
                        height={height}
                        columnWidth={columnWidth}
                        rowHeight={rowHeight}
                        columnCount={columnCount}
                        rowCount={Math.ceil(props.items.length / columnCount)}
                        cellRenderer={(props) => gridCellRenderer(props.columnIndex, props.rowIndex, columnWidth, rowHeight)}
                        scrollTop={scrollTop}
                        autoHeight
                    />
                );
            }}
        </WindowScroller>
    );
}

import React, { useState } from "react";
import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import ContactCommentSchema from "../../Schemas/contact_comment.schema.json";
import { post } from "../../utils";
import Form, { IFormResult, FormResultStatus } from "../../Components/FinalForm";

export default function Contact() {
    return (
        <Template noIEDetection>
            <Section padding>
                <ContactInner />
            </Section>
        </Template>
    );
}

export const ContactInner = () => {
    const [formData, setFormData] = useState({});

    const submit = async () => {
        let returnRes: IFormResult = {};
        await post("services/contact-message", formData).then(
            (res) => {
                if (res.status === 200) {
                    returnRes = {
                        status: FormResultStatus.SUCCESS,
                        clearInput: true,
                        message: "Message Sent. We will review it soon.",
                    };
                } else {
                    returnRes = {
                        status: FormResultStatus.FAILURE,
                        message: "Message failed to send. Please try again later.",
                    };
                }
            },
            (err) => {
                returnRes = {
                    status: FormResultStatus.FAILURE,
                    message: "Message failed to send. Please try again later.",
                };
            }
        );
        return returnRes;
    };

    return (
        <>
            <div className="contact-container">
                <div id="contact-address-container">
                    <h1>Head Office</h1>
                    <div className="address-info">
                        <p>
                            Trumode International Ltd <br />
                            <br />
                            4F., No.9, Loft Power,
                            <br />
                            No.28 Xiangyuan Road, Gongshu District, <br />
                            Hangzhou, China 310011 <br />
                        </p>
                        <a href="https://goo.gl/maps/MtaWRaVrdyAUwf8cA" target="_blank" rel="noopener noreferrer">
                            Google Map
                        </a>
                        <p>+86 571-85803671</p>
                    </div>
                </div>

                <div id="contact-form-container">
                    <h1>Let's Talk</h1>
                    <Form
                        id="contact-comment"
                        context={[formData, setFormData]}
                        schema={ContactCommentSchema}
                        actions={{
                            submit,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

import React from "react";
import "./index.scss";

import { useParams } from "react-router-dom";
import Template from "../../Template";
import MaterialDetailInner from "./MaterialDetailInner";
import { IdParams } from "../Common/interfaces";

export default function MaterialDetail() {
    let { id } = useParams<IdParams>();

    return (
        <Template>
            <div className="sample-full-container">{id && <MaterialDetailInner id={id} imageStyle={"sample_full"} />}</div>
        </Template>
    );
}

import Axios from "axios";
import OSS from "ali-oss";
import { ThunkDispatch } from "redux-thunk";
import { CombinedState, Action } from "redux";;


export const getEnv = (key: string) => {
    return process.env[`REACT_APP_${key}`];
};

/**
 * Get the Backend server IP
 * @param apiRoute 
 * @returns 
 */
export const getServer = (apiRoute: string) => {
    const prefix = process.env.NODE_ENV === "development" ? "DEVELOP" : "PROD";
    
    // Explicit Server Endpoint Setting
    const serverEndpoint = localStorage.getItem("serverEndpoint");
    if (serverEndpoint) {
        const serverEndpointExpired = localStorage.getItem("serverEndpointExpired");
        if (serverEndpointExpired) {
            if (parseInt(serverEndpointExpired) > Date.now()) {
                return serverEndpoint + "/api/" + apiRoute;
            }
            else {
                localStorage.removeItem("serverEndpoint");
                localStorage.removeItem("serverEndpointExpired");
            }
        } else {
            return serverEndpoint + "/api/" + apiRoute;
        }
    }
    return getEnv(`SERVER_URL_${prefix}`) + "/api/" + apiRoute;
};

export const get = (apiRoute: string) => {
    const accessToken = localStorage.getItem("accessToken");

    const headers = accessToken
        ? {
              Authorization: `Basic ${accessToken}`,
          }
        : {};

    return Axios.get(getServer(apiRoute), { headers, timeout: 3000 });
};

export const post = (apiRoute: string, data?: object) => {
    const accessToken = localStorage.getItem("accessToken");

    const headers = accessToken
        ? {
              Authorization: `Basic ${accessToken}`,
          }
        : {};

    return Axios.post(getServer(apiRoute), data, { headers, timeout: 3000 });
};

export const postForm = (apiRoute: string, formData?: FormData) => {
    const accessToken = localStorage.getItem("accessToken");

    const headers = accessToken
        ? {
              Authorization: `Basic ${accessToken}`,
              "Content-Type": "multipart/form-data",
          }
        : {
              "Content-Type": "multipart/form-data",
          };

    return Axios.post(getServer(apiRoute), formData, { headers });
};

export interface IOSSToken {
    SecurityToken: string;
    AccessKeyId: string;
    AccessKeySecret: string;
    Expiration: string;
}

export const ossClient = () => {
    const ossToken = getLocalOssToken();
    try {
        if (ossToken && validateOssToken(ossToken)) {
            let { REACT_APP_OSS_BUCKET, REACT_APP_OSS_ENDPOINT } = process.env;
            const ossClient = new OSS({
                accessKeyId: ossToken.AccessKeyId,
                accessKeySecret: ossToken.AccessKeySecret,
                stsToken: ossToken.SecurityToken,
                endpoint: REACT_APP_OSS_ENDPOINT,
                bucket: REACT_APP_OSS_BUCKET,
                secure: true,
            });
            return ossClient;
        } else {
            if (!localStorage.getItem("ossTokenRequesting")) updateOssToken();
            return null;
        }
    } catch (e) {
        console.error("Error creating OSS Client", e);
        return null;
    }
};

const UrlPool: { [key: string]: { url: string; time: number } } = {};
const EXPIRES = 900;

// Advanced security with STS token validation
export const oss = (path: string, imageProcess?: ImageProcess): string => {
    if (!path) return "";
    let cleanPath = path.replace(/\\/g, "/");
    const ossToken = getLocalOssToken();
    try {
        const cacheKey = cleanPath + "_" + JSON.stringify(imageProcess);
        const currentTime = new Date().getTime();
        const cache = UrlPool[cacheKey];
        if (cache && currentTime < cache.time + EXPIRES * 1000 - 5000) {
            return cache.url;
        } else if (ossToken && validateOssToken(ossToken)) {
            const client = ossClient();
            if (!client) {
                return "";
            }
            const signedUrl = client.signatureUrl(cleanPath, {
                expires: EXPIRES,
                process: imageProcessStringfy(imageProcess),
            });

            UrlPool[cacheKey] = {
                url: signedUrl,
                time: new Date().getTime(),
            };

            return signedUrl;
        } else {
            if (!localStorage.getItem("ossTokenRequesting")) updateOssToken();
            return "";
        }
    } catch (e) {
        console.error("Error creating OSS Client or Signing OSS URL", e);
        return "";
    }
};

const validateOssToken = (ossToken: IOSSToken): boolean => {
    // Only check the validation time currently
    const exp = new Date(ossToken.Expiration);
    const now = new Date();
    return exp > now;
};

const getLocalOssToken = (): IOSSToken | null => {
    try {
        const ossTokenStr = localStorage.getItem("ossToken");
        if (!ossTokenStr) return null;
        return JSON.parse(ossTokenStr) as IOSSToken;
    } catch (e) {
        console.error("Error getting local OSS Token", e);
        return null;
    }
};

const updateOssToken = () => {
    localStorage.setItem("ossTokenRequesting", "true");
    get("services/sts-token").then(
        (res) => {
            localStorage.removeItem("ossTokenRequesting");
            localStorage.setItem("ossToken", JSON.stringify(res.data));
            window.location.reload();
        },
        (err) => {
            localStorage.removeItem("ossTokenRequesting");
            console.error("Error when update OSS Token", err);
            window.location.reload();
        }
    );
};

interface ImageProcess {
    style?: string;
    resize?: string[];
    scale?: string[];
    cut?: string[];
    rotate?: string[];
}

const imageProcessStringfy = (imageProcess?: ImageProcess): string => {
    if (!imageProcess) return "{}";
    let str = "";
    if (imageProcess.style) {
        str += `style/${imageProcess.style}`;
    }
    if (imageProcess.resize) {
        str += "image/resize";
        for (const arg of imageProcess.resize) {
            str += "," + arg;
        }
    }
    if (imageProcess.scale) {
        str += "image/scale";
        for (const arg of imageProcess.scale) {
            str += "," + arg;
        }
    }
    if (imageProcess.cut) {
        str += "image/cut";
        for (const arg of imageProcess.cut) {
            str += "," + arg;
        }
    }
    if (imageProcess.rotate) {
        str += "image/rotate";
        for (const arg of imageProcess.rotate) {
            str += "," + arg;
        }
    }
    return str;
};

export const imageOnError = (e: React.SyntheticEvent, fallBackSrc?: string) => {
    const el = e.target as HTMLElement;
    if (fallBackSrc && el.getAttribute("src") !== fallBackSrc) {
        el.setAttribute("src", fallBackSrc);
    } else {
        el.setAttribute(
            "src",
            "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUwIDUwIiBmaWxsPSIjOTk5Ij48cGF0aCBkPSJNIDIuOTkwMjM0NCAxLjk5MDIzNDQgQSAxLjAwMDEgMS4wMDAxIDAgMCAwIDIuMjkyOTY4OCAzLjcwNzAzMTIgTCA0Ni4yOTI5NjkgNDcuNzA3MDMxIEEgMS4wMDAxIDEuMDAwMSAwIDEgMCA0Ny43MDcwMzEgNDYuMjkyOTY5IEwgNDQuNDE0MDYyIDQzIEwgNDggNDMgTCA0OCA3IEwgOC40MTQwNjI1IDcgTCAzLjcwNzAzMTIgMi4yOTI5Njg4IEEgMS4wMDAxIDEuMDAwMSAwIDAgMCAyLjk5MDIzNDQgMS45OTAyMzQ0IHogTSAyIDcgTCAyIDQzIEwgMzguNzA3MDMxIDQzIEwgMzYuODUzNTE2IDQxLjE0NjQ4NCBMIDI3LjA2MDU0NyAzMS4zNTM1MTYgTCAyNC43MDcwMzEgMzMuNzA3MDMxIEMgMjQuMzE2MDMxIDM0LjA5ODAzMSAyMy42ODM5NjkgMzQuMDk4MDMxIDIzLjI5Mjk2OSAzMy43MDcwMzEgQyAyMi45MDE5NjkgMzMuMzE2MDMxIDIyLjkwMTk2OSAzMi42ODM5NjkgMjMuMjkyOTY5IDMyLjI5Mjk2OSBMIDI1LjY0NjQ4NCAyOS45Mzk0NTMgTCAxNS41ODk4NDQgMTkuODgyODEyIEwgNCAzMC42OTkyMTkgTCA0IDkgTCA0LjcwNzAzMTIgOSBMIDIuNzA3MDMxMiA3IEwgMiA3IHogTSAxMC40MTQwNjIgOSBMIDQ2IDkgTCA0NiAzNi41ODU5MzggTCAzNC43MDcwMzEgMjUuMjkyOTY5IEMgMzQuMzE2MDMxIDI0LjkwMTk2OSAzMy42ODM5NjkgMjQuOTAxOTY5IDMzLjI5Mjk2OSAyNS4yOTI5NjkgTCAzMCAyOC41ODU5MzggTCAxMC40MTQwNjIgOSB6IE0gMzUgMTUgQSAzIDMgMCAwIDAgMzUgMjEgQSAzIDMgMCAwIDAgMzUgMTUgeiIvPjwvc3ZnPg=="
        );
        el.style.width = "48px";
        el.style.height = "48px";
    }
};

interface AnimateConfig {
    removeWhenFinished?: boolean;
    callBack?: Function;
}

interface IAnimateHistory {
    [key: string]: string[];
}

const animateHistory: IAnimateHistory = {};

export const animate = (id: string, className: string, config?: AnimateConfig) => {
    const el = document.querySelector("#" + id);
    if (!el) {
        if (config?.callBack) config.callBack();
        return;
    }

    const list: string[] = className.split(" ");
    if (animateHistory[id]) {
        el.classList.remove(...animateHistory[id]);
        animateHistory[id] = [];
    }
    el.classList.add(...list);
    animateHistory[id] = list;

    el.addEventListener("animationend", () => {
        if (config?.removeWhenFinished) el.classList.remove(...list);
        if (config?.callBack) config.callBack();
    });
};

export const ValidImageExtensions = new Set(["jpg", "jpeg", "png", "gif", "bmp", "tiff"]);

export const onFieldChange = (e: React.ChangeEvent, state: { [key: string]: any }, setState: Function) => {
    const el = e.target as HTMLInputElement;
    setState({
        ...state,
        [el.name]: el.value,
    });
};

export enum SERVER_STATUS {
    NOT_START = 1234718,
    NOT_FOUND = 8475166,
}

export const Trans = (type: string, key: string, lan: string, defaultString?: string) => {
    const storage = localStorage.getItem("TRANSLATIONS");
    if (storage) {
        const tags = JSON.parse(storage);
        if (tags[type] && tags[type][key]) return tags[type][key][`label_${lan}`];
    } else {
        return defaultString || key;
    }
};

export const R = (key?: string, subKey?: string) => {
    const resourcesStr = localStorage.getItem("resources");
    if (resourcesStr) {
        const resources = JSON.parse(resourcesStr);
        if (!key) {
            return resources["translations"]["translations"];
        } else if (!subKey) return resources[key];
        else if (resources[key]) return resources[key][subKey];
        else return {};
    } else {
        return {};
    }
};

export const keyToLabel = (key?: string) => {
    if (!key) return "";
    return key
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

export interface ISearchQuery {
    [key: string]: string[];
}

export class SearchQuery {
    private _store: ISearchQuery = {};
    private _secondFilters: ISearchQuery = {};

    constructor(query: string) {
        if (query && query.length > 0) {
            query.split(",").forEach((condition: string) => {
                const [key, value] = condition.split(":");
                if (key && value) {
                    if (key.startsWith("_")) this._secondFilters[key.replace(/_/g, "")] = value.split("&");
                    else this._store[key] = value.split("&");
                }
            });
        }
    }

    getQueries = () => this._store;

    getSecondFilters = () => this._secondFilters;

    toString = () => {
        const parts = [];
        for (const key of Object.keys(this._store)) {
            if (this._store[key] && this._store[key].length > 0) parts.push(key + ":" + this._store[key].join("&"));
        }
        return parts.join(",");
    };
}

export function ieDetection() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf("MSIE ");
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
    }

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf("rv:");
        return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
    }
}

/**
 * Get the correct width of the client
 */
export function getClientWidth() {
    return document.documentElement["clientWidth"];
    // return Math.max(
    //     document.documentElement["clientWidth"],
    //     document.body["scrollWidth"],
    //     document.documentElement["scrollWidth"],
    //     document.body["offsetWidth"],
    //     document.documentElement["offsetWidth"]
    // );
}

export function isMobile() {
    return getClientWidth() < 600;
}

/**
 * Handling Action Error
 * @param dispatch 
 * @param err 
 * @param action 
 * @param isStatus 
 */
export function handleError(dispatch: ThunkDispatch<CombinedState<any>, unknown, Action<String>>, err: any, action: string, isStatus: string) {
    if (err.response) {
        const res = err.response;
        const message = res.data? (res.data.clientMessage? res.data.clientMessage : res.data.message):res.message
        console.error(`Request '${action}' failed due to: `, res);
        dispatch({
            type: action,
            payload: {
                [isStatus]: false,
                error: {
                    statusCode: err.status,
                    clientMessage: message
                }
            }
        });
    } else {
        // Network issue
        console.error(`Request '${action}' failed due to: `, err.message);
        dispatch({
            type: action,
            payload: {
                [isStatus]: false,
                error: {
                    clientMessage: err.message
                }
            }
        });

        const backupServer = getEnv("SERVER_URL_PROD_BACKUP");
        if (backupServer && localStorage.getItem("serverEndpoint") !== backupServer) {
            localStorage.setItem("serverEndpoint", backupServer);
            // 1 Hour of Backup Server
            localStorage.setItem("serverEndpointExpired", (Date.now() + 3600 * 1000).toString());
            window.location.reload();
        } else {
            console.error("No backup servers available");
        }
    }
}
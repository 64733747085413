import React, { useState } from "react";
import Template from "../../Template";
import { Section } from "../../Components/Section";

import PasswordResetSchema from "../../Schemas/password_reset.schema.json";
import Form, { IFormResult, FormResultStatus } from "../../Components/FinalForm";
import { post } from "../../utils";

export default function PasswordResetRequestPage() {
    const [formData, setFormData] = useState<{ [field: string]: any }>({});

    const submit = async () => {
        let returnRes: IFormResult = {};

        await post("accounts/password-reset", {
            account: formData.username,
        }).then(
            (res) => {
                returnRes = {
                    status: FormResultStatus.SUCCESS,
                    clearInput: true,
                    message:
                        "Thanks! We just sent you an email with an unique link to reset your password. Please notice that the link is only valid for 24 hours. If you didn't received any mail, please check your spam mailbox, or try again after 5 mins. If you sent multiple requests for a single account, only the latest one is valid.",
                };
            },
            (err) => {
                const data = err.response.data;
                returnRes = {
                    status: FormResultStatus.FAILURE,
                    clearInput: false,
                    message:
                        data.clientMessage ||
                        "Sorry, we are currently unable to process your request, please try again later. Or contact us from 'About Us' -> 'Contact'.",
                };
            }
        );

        return returnRes;
    };

    return (
        <Template>
            <Section padding full containerStyle={{ background: "#eee" }}>
                <h1>Password Reset</h1>
                <p>Forget your password? Don't worry, we've got you covered!</p>
            </Section>
            <Section padding form>
                <Form
                    id="password_reset"
                    schema={PasswordResetSchema}
                    context={[formData, setFormData]}
                    actions={{
                        submit,
                    }}
                />
            </Section>
        </Template>
    );
}

import React, { useEffect, useState, useRef } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Reducers";

import { WindowScroller, List, CellMeasurer, CellMeasurerCache, ListRowProps } from "react-virtualized";

import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { get, oss, ossClient, ValidImageExtensions } from "../../utils";
import { Trend } from ".";
import { FullLoginPage } from "../../Components/FullLoginPage";
import { CommentSection } from "../../Components/CommentSection";
import { ScrollToTopButton } from "../../Components/ScrollToTopButton";
import Loading from "../../Components/Loading/Loading";
import { getSavedItemAction, postSaveItemAction } from "../../Actions";

interface ITrendDetailProps {
    name: string;
}

export default function TrendDetail(props: RouteComponentProps<ITrendDetailProps>) {
    const account = useSelector((state: RootState) => state.account);
    const preference = useSelector((state: RootState) => state.preference);
    const dispatch = useDispatch();

    const [trend, setTrend] = useState<Trend>();
    const [slides, setSlides] = useState<string[] | null>(null);
    const [commentHide, setCommentHide] = useState<boolean>(
        localStorage.getItem("option_default_trend_comment_hide") === "true"
    );
    const history = useHistory();
    const slidesContainerRef = useRef<HTMLDivElement>(null);

    // Resize Rerender
    const [, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    });

    useEffect(() => {
        if (!preference.savedItems) dispatch(getSavedItemAction());
    }, [dispatch, preference.savedItems]);

    useEffect(() => {
        const handler = () => setDimensions({ height: window.innerHeight, width: window.innerWidth });
        window.addEventListener("resize", handler);
        return () => {
            window.removeEventListener("resize", handler);
        };
    }, []);

    useEffect(() => {
        get(`trends/?name=${props.match.params.name}`).then(
            (res) => {
                if (res.data) {
                    const trend = res.data;
                    if (!trend) return;
                    setTrend(trend);

                    const prefix = `assets/trends/${trend.name}/`;

                    const client = ossClient();
                    if (!client) return;

                    client
                        .list(
                            {
                                prefix,
                                "max-keys": 1000
                            },
                            {}
                        )
                        .then(
                            (res) => {
                                const slides: string[] = [];
                                if (!res || !res.objects || res.objects.length === 0) {
                                    setSlides(slides);
                                    return;
                                }

                                for (const obj of res.objects) {
                                    const parts = obj.name.split(".");
                                    const ext = parts[parts.length - 1].toLowerCase();
                                    if (ValidImageExtensions.has(ext)) {
                                        slides.push(obj.name);
                                    }
                                }
                                setSlides(slides);
                            },
                            (err) => {
                                console.error(err);
                                setSlides([]);
                            }
                        );
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }, [props.match.params.name]);

    // Access Control
    if (!account.user && !localStorage.getItem("accessToken")) {
        return <FullLoginPage />;
    }

    if (!trend) return <Template />;

    const cache = new CellMeasurerCache({
        defaultHeight: 600,
        fixedWidth: true
    });

    const rowRenderer = ({ index, isScrolling, key, parent, style }: ListRowProps) => {
        if (!slides || !slides[index]) return null;
        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({ measure, registerChild }) => (
                    <div
                        className="trend-slide mt4"
                        ref={registerChild as (instance: HTMLDivElement | null) => void}
                        style={style}
                    >
                        <img
                            onLoad={measure}
                            src={oss(slides[index], { style: "full_carousol_desk" })}
                            alt={slides[index]}
                        />
                        <div style={{ height: 32 }}></div>
                    </div>
                )}
            </CellMeasurer>
        );
    };

    const renderSlides = () => {
        if (!slides) return <Loading />;

        if (slides.length === 0) return <h2>This trend currently has no image. Please come back later.</h2>;

        if (!slidesContainerRef || !slidesContainerRef.current) return null;
        const width = (slidesContainerRef.current as HTMLDivElement).offsetWidth;
        return (
            <WindowScroller>
                {({ height, scrollTop }) => (
                    <List
                        height={height}
                        autoHeight
                        width={width}
                        autoWidth
                        rowCount={slides.length}
                        rowRenderer={rowRenderer}
                        scrollTop={scrollTop}
                        deferredMeasurementCache={cache}
                        rowHeight={cache.rowHeight}
                    />
                )}
            </WindowScroller>
        );
    };

    const onCommentShow = (e: React.MouseEvent) => {
        e.stopPropagation();
        setCommentHide(false);
    };

    const onCommentHide = (e: React.MouseEvent) => {
        e.stopPropagation();
        setCommentHide(true);
    };

    const onBack = () => {
        history.goBack();
    };

    const isFavorite =
        preference.savedItems && preference.savedItems.trends && preference.savedItems.trends.indexOf(trend._id) !== -1;

    const toggleFavorite = () => {
        dispatch(
            postSaveItemAction({
                favoriteActions: [
                    {
                        resourceType: "trends",
                        id: trend._id,
                        setFavorite: !isFavorite
                    }
                ]
            })
        );
    };

    let commentContainerClassList = ["mt4"];
    let commentContainerStyle: React.CSSProperties = { transition: "250ms ease-out", whiteSpace: "pre" };
    if (commentHide) {
        commentContainerClassList.push("fab");
    } else {
        commentContainerClassList.push("modal trend-comment-section");
        commentContainerStyle = { ...commentContainerStyle };
    }

    const dateString = trend.time_create ? new Date(trend.time_create).toLocaleDateString() : undefined;

    const isGuest = !account.user || account.user.name === "guest";

    return (
        <Template className="trend-page">
            <Section className="trend-header">
                <h1>{trend.title}</h1>
                <h2>{trend.subtitle}</h2>
            </Section>
            <Section className="trend-info-bar">
                <ul>
                    {trend.season && <li>{trend.season}</li>}
                    {trend.cat && <li>{trend.cat}</li>}
                    {trend.time_create ? <li>{dateString}</li> : ""}
                </ul>
            </Section>
            <Section className="trend-slides" noPointerEvents noUserSelect noContextMenu style={trend.slides_style}>
                <div ref={slidesContainerRef} style={{ width: "100%", height: "100%" }}>
                    {renderSlides()}
                </div>
            </Section>
            <div className="fab-container">
                <div className="fab" onClick={onBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                        <path d="M 10.59375 13 L 19.179688 4.234375 C 19.5625 3.84375 19.558594 3.21875 19.171875 2.828125 L 17.636719 1.292969 C 17.242188 0.902344 16.609375 0.902344 16.21875 1.296875 L 5.292969 12.292969 C 5.097656 12.488281 5 12.742188 5 13 C 5 13.257813 5.097656 13.511719 5.292969 13.707031 L 16.21875 24.703125 C 16.609375 25.097656 17.242188 25.097656 17.636719 24.707031 L 19.171875 23.171875 C 19.558594 22.78125 19.5625 22.15625 19.179688 21.765625 Z" />
                    </svg>
                    Back
                </div>
                <ScrollToTopButton />
                {!isGuest && (
                    <div className={`fab fab-save ${isFavorite ? "active" : "inactive"}`} onClick={toggleFavorite}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="#191919">
                            <path d="M15.765,2.434l2.875,8.512l8.983,0.104c0.773,0.009,1.093,0.994,0.473,1.455l-7.207,5.364l2.677,8.576 c0.23,0.738-0.607,1.346-1.238,0.899L15,22.147l-7.329,5.196c-0.63,0.447-1.468-0.162-1.238-0.899l2.677-8.576l-7.207-5.364 c-0.62-0.461-0.3-1.446,0.473-1.455l8.983-0.104l2.875-8.512C14.482,1.701,15.518,1.701,15.765,2.434z" />
                        </svg>
                        Save
                    </div>
                )}
                <div
                    className={commentContainerClassList.join(" ")}
                    style={commentContainerStyle}
                    onClick={onCommentShow}
                >
                    {commentHide ? (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <path d="M22,3H2v16h16l4,4V3z M14,16H7v-2h7V16z M17,12H7v-2h10V12z M17,8H7V6h10V8z" />
                            </svg>
                            Comment
                        </>
                    ) : (
                        <CommentSection
                            resourceType="trend"
                            id={trend.title || trend.name}
                            header="Write here the reference of your selection and your comments"
                            secondaryButton={
                                <button className="full" onClick={onCommentHide}>
                                    Hide
                                </button>
                            }
                        />
                    )}
                </div>
            </div>
        </Template>
    );
}

import React, { useEffect } from "react";
import { get } from "../../utils";
import CarousolWidget from "./CarousolWidget";
import { WidgetType } from "./WidgetType";

export interface BaseWidgetProps {
    name: string;
    type: string;
    title?: string;
    style?: React.CSSProperties;
    time_create?: Date;
    time_modify?: Date;

    // For subtypes of widgets and error handling
    [field: string]: any;
}

export default function Widget(props: { name: string }) {
    const [widget, setWidget] = React.useState<BaseWidgetProps | null>();

    useEffect(() => {
        if (!widget) {
            get(`widgets/?name=${props.name}`).then(
                (res) => {
                    if (res.data) setWidget(res.data);
                    else setWidget({ name: "null", type: WidgetType.BASE_WIDGET });
                },
                (err) => {
                    console.error(err);
                    setWidget({ name: "null", type: WidgetType.BASE_WIDGET });
                }
            );
        }
    });

    if (!widget) {
        return <div className="widget-loading" />;
    }
    if (widget.type === WidgetType.BASE_WIDGET) {
        return <div className="widget-null" />;
    }

    // Render Specificic Type of Widget
    switch (widget.type) {
        case WidgetType.CAROUSOL_WIDGET:
            return <CarousolWidget {...widget} />;
        default:
            return <div className="widget-null" />;
    }
}

export interface BaseWdigetEditorProps {
    context: [{ [field: string]: any }, Function];
    submit: Function;
}

import {
    LOGIN_ACTION,
    GET_LOGIN_ACTION,
    LoginActionRequest,
    LOGOUT_ACTION,
    SAVE_ITEM_ACTION,
    SaveItemActionRequest,
    SaveItemActionResponse
} from "./types";
import { post, get, handleError } from "../utils";

import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../Reducers";

export type ActionResponse = {
    success: boolean;
    clientMessage?: string;
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

// Login Action
export const loginAction =
    (req: LoginActionRequest): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: LOGIN_ACTION,
            payload: {
                isLogging: true
            }
        });

        post("login", {
            uname: req.username,
            upwd: req.password
        }).then(
            (res) => {
                if (res.status === 200) {
                    dispatch({
                        type: LOGIN_ACTION,
                        payload: true
                    });

                    if (res.data.accessToken) localStorage.setItem("accessToken", res.data.accessToken);
                    if (res.data.ossToken) localStorage.setItem("ossToken", JSON.stringify(res.data.ossToken));

                    // Directly call the getLoginAction
                    dispatch(getLoginAction());
                }
            },
            (err) => handleError(dispatch, err, GET_LOGIN_ACTION, "isLogging")
        );
    };

// Get Login Action
export const getLoginAction = (): AppThunk => async (dispatch) => {
    dispatch({
        type: GET_LOGIN_ACTION,
        payload: {
            isGettingLogin: true
        }
    });

    get("login").then(
        (res) => {
            if (res.data.resources) localStorage.setItem("resources", JSON.stringify(res.data.resources));
            if (res.data.accessToken) localStorage.setItem("accessToken", res.data.accessToken);
            if (res.data.ossToken) localStorage.setItem("ossToken", JSON.stringify(res.data.ossToken));

            dispatch({
                type: GET_LOGIN_ACTION,
                payload: {
                    isGettingLogin: false,
                    user: res.data.user
                }
            });
        },
        (err) => {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("ossToken");
            localStorage.removeItem("resources");

            handleError(dispatch, err, GET_LOGIN_ACTION, "isGettingLogin");
        }
    );
};

// Logout Action
export const logoutAction = (): Action => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("ossToken");
    localStorage.removeItem("resources");

    return {
        type: LOGOUT_ACTION
    };
};

// Get Saved Item
export const getSavedItemAction = (): AppThunk => async (dispatch) => {
    dispatch({
        type: SAVE_ITEM_ACTION,
        payload: {
            isGetting: true
        }
    });

    get("accounts/favorites").then(
        (res) => {
            const savedItems: SaveItemActionResponse = res.data;
            dispatch({
                type: SAVE_ITEM_ACTION,
                payload: {
                    isSaving: false,
                    savedItems
                }
            });
        },
        (err) => {
            const res = err.response;
            console.error("Post Save Item error", res);
            dispatch({
                type: SAVE_ITEM_ACTION,
                payload: {
                    isSaving: false,
                    error: {
                        statusCode: err.status,
                        clientMessage: res.data.clientMessage
                    }
                }
            });
        }
    );
};

// Save Item
export const postSaveItemAction =
    (req: SaveItemActionRequest): AppThunk =>
    async (dispatch) => {
        dispatch({
            type: SAVE_ITEM_ACTION,
            payload: {
                isSaving: true
            }
        });

        post("accounts/favorites", req).then(
            (res) => {
                const savedItems: SaveItemActionResponse = res.data;
                dispatch({
                    type: SAVE_ITEM_ACTION,
                    payload: {
                        isSaving: false,
                        savedItems
                    }
                });
            },
            (err) => {
                const res = err.response;
                console.error("Post Save Item error", res);
                dispatch({
                    type: SAVE_ITEM_ACTION,
                    payload: {
                        isSaving: false,
                        error: {
                            statusCode: err.status,
                            clientMessage: res.data.clientMessage
                        }
                    }
                });
            }
        );
    };

import React, { useState } from "react";

import "./index.scss";
import logoLight from "./logo.svg";
import logoDark from "./logoDark.svg";

import HeaderMenu from "./HeaderMenu";

import { Link } from "react-router-dom";
import MultiLevelMenu, { IMenuItem } from "../MultiLevelMenu";
import UserButton from "./UserButton";
import Dragon from "../Footer/dragon";
import { isMobile } from "../../utils";
import MobileHeader from "./index.m";

interface IHeaderProps {
    theme?: string;
}

export default function Header(props: IHeaderProps) {
    const [menuItem, setMenuItem] = useState<IMenuItem>({ name: "null" });

    if (isMobile()) {
        return <MobileHeader {...props} />;
    }

    return (
        <header id="main-header" className={`tr-container ${props.theme || "light"}`} onMouseLeave={() => setMenuItem({ name: "null" })}>
            <div id="header-main">
                <div id="header-nav">
                    <div id="header-logo-container">
                        <Link to="/">
                            <img src={props.theme === "dark" ? logoDark : logoLight} alt="Trumode Logo" />
                        </Link>
                    </div>
                    <div id="header-menu-container">
                        <HeaderMenu itemHoverCallback={setMenuItem} />
                    </div>
                </div>
                <div id="header-extra">
                    <UserButton />
                </div>
            </div>
            {menuItem && menuItem["submenu"] && (
                <div id="header-extension">
                    <div className="dragon-overlay">
                        <Dragon />
                    </div>
                    <MultiLevelMenu menu={menuItem} />
                </div>
            )}
        </header>
    );
}

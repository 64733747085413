import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";

import { Accessory } from ".";
import Carousol, { CarousolSlide } from "../../Components/Carousol";
import { oss, animate, R, keyToLabel, post } from "../../utils";
import Config from "../../Config/config.json";
import { CommentSection } from "../../Components/CommentSection";
import { useDispatch } from "react-redux";
import { postSaveItemAction } from "../../Actions";
import Loading from "../../Components/Loading/Loading";
import { Section } from "../../Components/Section";

interface IAccessoryDetailInnerProps {
    id: string;
    accessory?: Accessory;
    onClose?: Function;
    afterClose?: Function;
    imageStyle?: string;
}

export default function AccessoryDetailInner(props: IAccessoryDetailInnerProps) {
    const preference = useSelector((state: RootState) => state.preference);
    const isFavorite =
        preference.savedItems &&
        preference.savedItems.accessories &&
        preference.savedItems.accessories.indexOf(props.id) !== -1;
    const dispatch = useDispatch();
    const [accessory, setAccessory] = useState<Accessory | undefined>(props.accessory);
    const [error, setError] = useState(null);

    const account = useSelector((state: RootState) => state.account);

    useEffect(() => {
        post("samples/searchbyid", {
            id: props.id
        }).then(
            (res) => {
                if (res.data) setAccessory(res.data);
            },
            (err) => {
                if (err) {
                    setError(err.response);
                    console.error(err);
                    Promise.resolve(err);
                }
            }
        );
    }, [props.id]);

    if (error) {
        return (
            <Section full padding>
                <h1>Sorry, we cannot found the resource</h1>
            </Section>
        );
    }
    if (!accessory) return <Loading />;

    const isGuest = !account.user || account.user.name === "guest";

    const renderSlides = () => {
        const renderedSlides = [];
        for (let image of accessory.images || []) {
            renderedSlides.push(
                <CarousolSlide key={image} background={oss(image, { style: props.imageStyle || "sample_grid_item" })} />
            );
        }

        return (
            <Carousol
                name="showcase-item-detail-inner-carousol"
                config={{
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                        renderBullet: (index: number, className: string) =>
                            `<div class="${className}"><img src="${oss(
                                accessory && accessory.images ? accessory.images[index] : "",
                                {
                                    style: "sample_grid_item"
                                }
                            )}" /></div>`
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    },
                    loop: true
                }}
            >
                {renderedSlides}
            </Carousol>
        );
    };

    const onCloseButtonClick = () => {
        if (props.afterClose)
            animate(`sample-quick-view-${accessory.po}`, "animated fadeOut faster", { callBack: props.afterClose });
        if (props.onClose) props.onClose();
    };

    const renderContents = (contents: string[][]) => {
        try {
            const renderedContents = [];
            contents.sort((a: string[], b: string[]) => parseInt(b[1]) - parseInt(a[1]));
            for (const content of contents) {
                const ecoFlag = Config.ecoFriendlyContent.indexOf(content[0]) !== -1;
                renderedContents.push(
                    <div key={content[1] + content[0]} className="sdi-content">
                        <div className="content-present">{content[1]}%</div>
                        <div className={`content-name ${ecoFlag ? "eco" : ""}`}>
                            {R("content") && R("content").content && R("content").content[content[0]]
                                ? R("content").content[content[0]].label_en
                                : keyToLabel(content[0])}
                        </div>
                    </div>
                );
            }
            return (
                <div className="info-field mt4">
                    <h3>Content</h3>
                    {renderedContents}
                </div>
            );
        } catch (e) {
            console.error("Content for PO#:" + accessory.po + " is not valid");
            console.error(e);
        }
    };

    const renderTags = () => {
        const renderedTags = [];
        if (!accessory.tags) return null;
        for (const tag of accessory.tags) {
            renderedTags.push(
                <div key={`accessory-tag-${tag}`} className={`showcase-card-tag ${tag}`}>
                    {R("tags", "tags") && R("tags", "tags")[tag] ? R("tags", "tags")[tag].label_en : keyToLabel(tag)}
                </div>
            );
        }
        return <div className="sdi-tag-container">{renderedTags}</div>;
    };

    const toggleFavorite = () => {
        dispatch(
            postSaveItemAction({
                favoriteActions: [
                    {
                        resourceType: "accessories",
                        id: accessory._id,
                        setFavorite: !isFavorite
                    }
                ]
            })
        );
    };

    return (
        <div className="showcase-item-detail-inner">
            <div className="sdi-image-container">{renderSlides()}</div>
            <div className="sdi-main">
                <div className="modal-titlebar">
                    <div>
                        <h1>{[accessory.fabric, "Accessory"].join(" ")}</h1>
                        <span>{`PO#: ${accessory.po}`}</span>
                        {renderTags()}
                    </div>
                    <div className="h-flex je ac">
                        {!isGuest && (
                            <button
                                className={`sdi-fav circle h-flex ac jc ${isFavorite ? "active" : "inactive"}`}
                                onClick={() => toggleFavorite()}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="#191919">
                                    <path d="M15.765,2.434l2.875,8.512l8.983,0.104c0.773,0.009,1.093,0.994,0.473,1.455l-7.207,5.364l2.677,8.576 c0.23,0.738-0.607,1.346-1.238,0.899L15,22.147l-7.329,5.196c-0.63,0.447-1.468-0.162-1.238-0.899l2.677-8.576l-7.207-5.364 c-0.62-0.461-0.3-1.446,0.473-1.455l8.983-0.104l2.875-8.512C14.482,1.701,15.518,1.701,15.765,2.434z" />
                                </svg>
                            </button>
                        )}
                        {props.onClose && (
                            <button className="circle" onClick={onCloseButtonClick}>
                                ×
                            </button>
                        )}
                    </div>
                </div>
                {renderContents(accessory.content)}
                {(accessory.gsm || accessory.gsm_range) && (
                    <div className="info-field mt4">
                        <h3>Weight</h3>
                        {accessory.gsm && <span>{accessory.gsm} gsm</span>}
                        {accessory.gsm_range && (
                            <span>
                                {accessory.gsm_range[0]} gsm - {accessory.gsm_range[1]} gsm
                            </span>
                        )}
                    </div>
                )}
                {accessory.gauge && (
                    <div className="info-field mt4">
                        <h3>Weight</h3>
                        <span>{accessory.gauge}</span>
                    </div>
                )}
                {accessory.remark && (
                    <div className="info-field mt4">
                        <h3>Remark</h3>
                        <span>{accessory.remark}</span>
                    </div>
                )}

                <div className="mt4">
                    <CommentSection
                        resourceType="accessory"
                        id={accessory.po}
                        header={"Leave your comments and requests about this style"}
                    />
                </div>
            </div>
        </div>
    );
}

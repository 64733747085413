import React from "react";
import { Section } from "../../Components/Section";
import { Link } from "react-router-dom";
import Template from "../../Template";

export default function NotFoundPage() {
    return (
        <Template>
            <Section full padding>
                <h1>404 Not Found</h1>
                <p>
                    Since we are still developing this site, it's highly possible that the contents you are looking for is not ready for you. Please check
                    later.
                </p>
                <Link to="/">Back Home</Link>
            </Section>
        </Template>
    );
}

import React from "react";
import "./index.scss";

import { useParams } from "react-router-dom";
import Template from "../../Template";
import AccessoryDetailInner from "./AccessoryDetailInner";
import { IdParams } from "../Common/interfaces";

export default function AccessoryDetail() {
    let { id } = useParams<IdParams>();

    return (
        <Template>
            <div className="sample-full-container">{id && <AccessoryDetailInner id={id} imageStyle={"sample_full"} />}</div>
        </Template>
    );
}

import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Frontpage from "./Pages/FrontPage";
import Samples from "./Pages/Samples";
import SampleDetail from "./Pages/Samples/SampleDetail";
import Materials from "./Pages/Materials";
import MaterialDetail from "./Pages/Materials/MaterialDetail";
import Accessories from "./Pages/Accessories";
import AccessoryDetail from "./Pages/Accessories/AccessoryDetail";
import Trends from "./Pages/Trends";
import TrendOverview from "./Pages/Trends/TrendOverview";
import TrendDetail from "./Pages/Trends/TrendDetail";
import About from "./Pages/About";
import Contact from "./Pages/About/Contact";
import Enroll from "./Pages/Enroll";
import PasswordResetRequestPage from "./Pages/PasswordReset";
import PasswordResetSubmitPage from "./Pages/PasswordReset/resettingPage";
import NotFoundPage from "./Pages/ErrorPages/404";
import { useDispatch, useSelector } from "react-redux";
import { getLoginAction } from "./Actions";
import { RootState } from "./Reducers";
import InNow from "./Pages/InNow";

export interface IUser {
    username: string;
    iat: number;
    exp: number;
}

export default function App() {
    const allStates = useSelector((state: RootState) => state);

    const dispatch = useDispatch();

    useEffect(() => {
        if (localStorage.getItem("ossTokenRequesting")) {
            localStorage.removeItem("ossTokenRequesting");
        }
        // if (!localStorage.getItem("accessToken")) {
        //     return;
        // }

        dispatch(getLoginAction());

        // Globally disable drag and drop to open image
        window.addEventListener(
            "dragover",
            function (e) {
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            "drop",
            function (e) {
                e.preventDefault();
            },
            false
        );
    }, [dispatch]);

    useEffect(() => {
        const keyPressListener = (e: KeyboardEvent) => {
            if (e.keyCode === 43 && e.shiftKey) {
                console.info(allStates);
            }
        };
        document.addEventListener("keypress", keyPressListener);
        return () => {
            document.removeEventListener("keypress", keyPressListener);
        };
    }, [allStates]);

    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Frontpage} />

                <Route path="/samples/q/:query" exact component={Samples} />
                <Route path="/samples/q/" exact component={Samples} />
                <Route path="/samples/:id" exact component={SampleDetail} />

                <Route path="/materials/q/:query" exact component={Materials} />
                <Route path="/materials/q/" exact component={Materials} />
                <Route path="/materials/:id" exact component={MaterialDetail} />

                <Route path="/Accessories/q/:query" exact component={Accessories} />
                <Route path="/Accessories/q/" exact component={Accessories} />
                <Route path="/Accessories/:id" exact component={AccessoryDetail} />

                <Route path="/trends/q/:query" exact component={Trends} />
                <Route path="/trends" exact component={TrendOverview} />
                <Route path="/trends/q/" exact component={Trends} />
                <Route path="/trends/:name" exact component={TrendDetail} />

                <Route path="/in-now/" exact component={InNow} />
                <Route path="/in-now/:name" exact component={TrendDetail} />

                <Route path="/about/" exact component={About} />
                <Route path="/contact/" exact component={Contact} />

                <Route path="/enroll/:id" exact component={Enroll} />

                <Route path="/password-reset" exact component={PasswordResetRequestPage} />
                <Route path="/password-reset/:id" exact component={PasswordResetSubmitPage} />

                <Route component={NotFoundPage} />
            </Switch>
        </Router>
    );
}

import React from "react";
import { Section } from "../../Components/Section";
import Template from "../../Template";


export default function TrendOverview() {
    return (
        <Template hideDummyHeader theme="dark">
            <Section id="trend-ov-header" full style={{ height: 650 }} containerStyle={{ backgroundImage: "url('/assets/images/trend_ov_bg.jpg')" }}>
                <div className="slogan">
                    Our best fashion trend books, presented by Trumode designers.
                </div>
            </Section>
            <Section full className="tiles featured">
                <div>
                </div>
            </Section>
        </Template>
    )
}
import React, { useEffect, useState } from "react";
import "./index.scss";

import { RouteComponentProps } from "react-router-dom";
import Template from "../../Template";
import { FullLoginPage } from "../../Components/FullLoginPage";
import { post } from "../../utils";
import SampleDetailInner from "./SampleDetailInner";
import { Sample } from ".";

type SampleParams = { id: string | undefined };

export default function SampleDetail(props: RouteComponentProps<SampleParams>) {
    const [sample, setSample] = useState<Sample | null>();

    useEffect(() => {
        post("samples/searchbyid", {
            po: props.match.params.id
        }).then(
            (res) => {
                setSample(res.data);
            },
            (err) => {
                if (err) {
                    console.error(err);
                    Promise.resolve(err);
                }
            }
        );
    }, [props.match.params.id]);

    // if (!localStorage.getItem("accessToken")) {
    //     return <FullLoginPage />;
    // }

    return (
        <Template>
            <div className="sample-full-container">
                {sample && <SampleDetailInner id={sample.po} sample={sample} imageStyle={"sample_full"} />}
            </div>
        </Template>
    );
}

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";

import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { post, SearchQuery, keyToLabel, getClientWidth } from "../../utils";
// import { FullLoginPage } from "../../Components/FullLoginPage";
import { Showcase } from "../../Components/Showcase";
import { ShowcaseItem, FilterWithOptions, FirstFilterChangeEvent, Option } from "../../Components/Showcase/types";

import Modal from "../../Components/Modal";
import AccessoryEdit from "./AccessoryEdit";
import AccessoryDetailInner from "./AccessoryDetailInner";
import loggingReducer from "../../Reducers/loggingReducer";

export interface Accessory extends ShowcaseItem {
    _id: string;
    po: string;
    content: string[][];
    gauge?: string;
    gsm?: string;
    gsm_range?: string[];
    fabric: string;
    tags?: string[];
    images?: string[];
    remark?: string;
}

interface AccessoryQueryParams {
    query: string;
}

const MANUAL_SORT_LIST = ["linen", "silk", "modal", "lyocell", "tencel", "sequin", "lurex"].sort();

export default function AccessoryPage() {
    const { query } = useParams<AccessoryQueryParams>();
    const account = useSelector((state: RootState) => state.account);

    const [searchQuery, setSearchQuery] = useState<SearchQuery>();
    const [searching, setSearching] = useState<boolean>(false);
    const [data, setData] = useState<{ [_id: string]: Accessory }>({});
    const [showcaseItems, setShowcaseItems] = useState<ShowcaseItem[]>([]);
    const [tags, setTags] = useState<{ key: string; freq: number }[]>([]);
    const history = useHistory();

    const [addingNew, setAddingNew] = useState<boolean>(false);
    const [displayingItem, setDisplayingItem] = useState<string | null>(null);
    const [editingItem, setEditingItem] = useState<string | null>(null);

    const isMobile = getClientWidth() < 600;

    useEffect(() => {
        if (!account.user) return;
        try {
            let searchQuery = new SearchQuery(query);
            
            // Use accessory cat there
            if (!query || query.length === 0) {
                searchQuery = new SearchQuery('cat:accessory');
            }

            console.log(query)
            console.log(`Search Query: ${searchQuery}`)

            setSearchQuery(searchQuery);
        } catch (e) {
            console.error("Invalid Search Query: ", query);
            // TODO: Better Error Handling
            history.push(" "); // Redirect to empty query
        }
    }, [query, history, account.user]);

    useEffect(() => {
        if (!searchQuery) return;
        setSearching(true);
        post("samples/search", {
            query: searchQuery.getQueries(),
            mode: "coarse"
        }).then(
            (res) => {
                setSearching(false);
                if (!res.data || res.data.length === 0) return;
                const accessories = res.data as Accessory[];
                const accessoryShowcaseItems: ShowcaseItem[] = [];
                const accessoryMap: { [_id: string]: Accessory } = {};

                console.log(accessories)

                accessories.forEach((accessory) => {
                    accessoryMap[accessory._id] = accessory;
                    accessoryShowcaseItems.push({
                        _id: accessory._id,
                        images: accessory.images,
                        cat: accessory.cat,
                        tags: accessory.tags,
                        po: accessory.po,
                        content: accessory.content,
                        fabric: accessory.fabric,
                        time_create: accessory.time_create,
                        time_update: accessory.time_modify,
                        cardProps: {
                            title: accessory.po,
                            titleRight: keyToLabel(accessory.cat || ""),
                            subtitle: `${keyToLabel(accessory.fabric)}`
                        }
                    });
                });
                setData(accessoryMap);
                setShowcaseItems(accessoryShowcaseItems);
            },
            (err) => {
                setSearching(false);
                console.warn(err.response.data);
                // TODO: Better Error Handling
                Promise.resolve(err);
            }
        );
    }, [searchQuery]);

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) return;
        const tagMap: { [key: string]: { key: string; freq: number } } = {};
        for (const _id of Object.keys(data)) {
            const item = data[_id];
            if (item.tags && item.tags.length > 0) {
                for (const tag of item.tags) {
                    if (tagMap[tag]) tagMap[tag].freq += 1;
                    else tagMap[tag] = { key: tag, freq: 1 };
                }
            }
        }

        const tagsArray = Object.values(tagMap);
        tagsArray.sort((a, b) => b.freq - a.freq);
        setTags(tagsArray);
    }, [data]);

    const filterOnChange = ({ event, key, option }: FirstFilterChangeEvent) => {
        if (!searchQuery) return;
        const query = searchQuery.getQueries();
        if (!query[key]) {
            query[key] = [];
        }

        // Multiple first filter solution

        // const index = query[key].indexOf(option);
        // if (index !== -1) {
        //     query[key].splice(index, 1);
        //     if (query[key].length === 0) delete query[key];
        // } else if (!query[key]) {
        //     query[key] = [option];
        // } else {
        //     query[key].push(option);
        // }

        // Single first filter solution
        const index = query[key].indexOf(option);
        if (index === -1) query[key] = [option];
        else query[key] = [];

        history.replace(searchQuery.toString() || " ");
    };

    const getFirstFilters = (): FilterWithOptions[] => {
        const q = searchQuery && searchQuery.getQueries() ? searchQuery.getQueries() : null;
        if (!q) {
            return [];
        }
        return [
            {
                key: "fabric",
                options: constructOptions(["flat", "circular"]),
                value: q ? q["fabric"] : []
            }
        ];
    };

    const constructOptions = (keys: string[]): Option[] => {
        const res: Option[] = [];
        keys.forEach((key) => res.push({ key }));
        return res;
    };

    const getSecondFilters = (): FilterWithOptions[] => {
        const secondFiltersValues = searchQuery?.getSecondFilters() || {};
        return [
            {
                key: "tags",
                options: tags,
                value: secondFiltersValues["tags"] ? secondFiltersValues["tags"][0] : ""
            },
            {
                key: "content",
                options: getContentOptions(),
                value: secondFiltersValues["content"] ? secondFiltersValues["content"][0] : ""
            }
        ];
    };

    const getContentOptions = (): Option[] => {
        const contentOptions: Option[] = [];
        for (const key of MANUAL_SORT_LIST) {
            contentOptions.push({
                key
            });
        }
        return contentOptions;
    };

    // Access Control
    // if (!account.user && !localStorage.getItem("accessToken")) {
    //     return <FullLoginPage />;
    // }

    return (
        <Template>
            <Section full frame padding>
                <Showcase
                    id="accessory-showcase"
                    title="Accessories"
                    resourceType="accessories"
                    items={showcaseItems}
                    searching={searching}
                    firstFilters={getFirstFilters()}
                    secondFilters={getSecondFilters()}
                    firstFilterOnChange={filterOnChange}
                    onAddNew={() => setAddingNew(true)}
                    onItemClick={(item) => {
                        setDisplayingItem(item._id);
                    }}
                    onItemEdit={(item) => setEditingItem(item._id)}
                    gridConfig={{
                        columns: isMobile ? 2 : 4,
                        imageRatio: 1.33
                    }}
                    searchQueryFields={["po", "cat", "tags", "fabric", "content"]}
                    searchQueryThreshold={0.2}
                />
            </Section>
            {addingNew && <AccessoryEdit onClose={() => setAddingNew(false)} />}
            {displayingItem && (
                <Modal
                    id={`sample-quick-view-${displayingItem}`}
                    backdrop
                    backdropClose
                    escClose
                    onClose={() => {}}
                    afterClose={() => setDisplayingItem(null)}
                    className="showcase-item-quick-view-modal"
                >
                    <AccessoryDetailInner
                        id={displayingItem}
                        accessory={data[displayingItem]}
                        onClose={() => {}}
                        afterClose={() => setDisplayingItem(null)}
                    />
                </Modal>
            )}
            {editingItem && <AccessoryEdit _id={data[editingItem]._id} onClose={() => setEditingItem(null)} />}
        </Template>
    );
}

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Modal from "../Modal";
import loginSchema from "../../Schemas/login.schema.json";
import { Link } from "react-router-dom";
import { loginAction, logoutAction } from "../../Actions";
import { RootState } from "../../Reducers";
import Form from "../FinalForm";
import { animate } from "../../utils";

interface ILoginModalProps {
    id: string;
    close: Function;
    inline?: boolean;
}

export default function LoginModal(props: ILoginModalProps) {
    const [formData, setFormData] = useState<{ [key: string]: any }>({});

    const dispatch = useDispatch();
    const account = useSelector((state: RootState) => state.account);

    const logout = () => {
        animate("user-modal", "animated fadeOut faster", {
            callBack: () => {
                props.close();
                dispatch(logoutAction());
            }
        });
    };

    if (!account.user || account.user.role === "guest") {
        // If the user isn't logged in, show the login menu
        return (
            <Modal
                id={props.id}
                backdrop
                backdropClose
                escClose
                titleBar="Partner Sign In"
                close={props.close}
                inline={props.inline}
                autoFocusId="username"
            >
                <Form
                    id="login-form"
                    schema={loginSchema}
                    context={[formData, setFormData]}
                    actions={{
                        submit: () => {}
                    }}
                    customized={{
                        submit: ({ errors }) => (
                            <div key="login-submit-button" className="button-group full">
                                <button
                                    name="submit"
                                    className="input-field-item"
                                    disabled={(errors && errors.length > 0) || account.login.isLogging}
                                    onClick={() =>
                                        dispatch(
                                            loginAction({
                                                username: formData.username,
                                                password: formData.password
                                            })
                                        )
                                    }
                                >
                                    {account.login.isLogging ? "One sec..." : "Submit"}
                                </button>
                            </div>
                        )
                    }}
                    fastSwitch
                />

                <div className="caption">
                    Forget your password?{" "}
                    <Link to="/password-reset" tabIndex={-1}>
                        Reset here
                    </Link>
                </div>

                <div className="caption">
                    Interested in becoming a Partner of Trumode? <Link to="/about">Learn more</Link>.
                </div>

                {account.login.error && <div className="callout error">{account.login.error.clientMessage}</div>}
            </Modal>
        );
    }

    // When the user is logged in, show the user menu
    return (
        <Modal
            id={props.id}
            backdrop
            backdropClose
            escClose
            titleBar="Welcome Back"
            close={props.close}
            inline={props.inline}
        >
            <div>
                You are logged in as {account.user.username}{" "}
                <button className="anchor" onClick={logout}>
                    Sign out
                </button>
            </div>
        </Modal>
    );
}

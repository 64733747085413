import React, { ReactNode } from "react";
import "./index.scss";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { ieDetection } from "../utils";
import ScrollToTop from "../Components/ScrollToTop";

interface ITemplateProps {
    hideHeader?: boolean;
    hideDummyHeader?: boolean;
    hideFooter?: boolean;
    children?: ReactNode;
    theme?: string;
    noIEDetection?: boolean;
    className?: string;
}

export default function Template(props: ITemplateProps) {
    const showIEBanner = () => {
        var ieVersion = ieDetection();
        if (!props.noIEDetection && ieVersion) {
            return (
                <div className="ie-unsupport-banner">
                    <div className="ie-unsupport-inner">
                        <div>
                            <img src="/assets/icons/sorry.png" alt="Sorry for your inconvenience" />
                        </div>
                        <h1>We are Sorry, Trumode.net is not currently supporting Internet Explorer</h1>
                        <div className="callout successful" style={{ margin: "32px 0" }}>
                            Please use one of the modern browsers to access Trumode.net. Our recommendations are{" "}
                            <a href="https://www.google.com/chrome/">Google Chrome</a>,{" "}
                            <a href="https://www.mozilla.org/firefox/new/">Mozilla Firefox</a>, or{" "}
                            <a href="https://www.microsoft.com/edge">Microsoft Edge</a>
                        </div>
                        <h2>Why?</h2>
                        <p style={{ padding: "16px 0" }}>
                            Microsoft Internet Explorer is a legacy software pre-installed in Windows only for backward
                            compatibility now. We are building Trumode.net with modern technologies, in order to provide
                            you with fast and secure website experience. That's the reason we, Microsoft and most part
                            of the Internet suggest people switch to a modern browser. At this time, please understand
                            we cannot provide services on IE, if you need any support, please{" "}
                            <a href="/contact">contact us</a>.
                        </p>
                        <p>
                            IT Department
                            <br />
                            Hangzhou Trumode Fashion Co.,Ltd
                        </p>
                    </div>
                </div>
            );
        } else return <main className={props.className}>{props.children}</main>;
    };

    return (
        <>
            <ScrollToTop />
            {props.hideHeader ? null : <Header theme={props.theme} />}
            {props.hideHeader || props.hideDummyHeader ? null : (
                <div className="dummy-header" style={{ background: "white" }} />
            )}
            {showIEBanner()}
            {props.hideFooter ? null : Footer()}
        </>
    );
}

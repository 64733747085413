import React, { FunctionComponent, useRef, useEffect } from "react";
import Swiper, { SwiperOptions } from "swiper";
import "./index.scss";

interface CarousolProps {
    name: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    config?: SwiperOptions;
}

export default function Carousol(props: CarousolProps) {
    const swiperDivRef = useRef<HTMLDivElement>(null);
    const swiperRef = useRef<Swiper>();

    useEffect(() => {
        if (!isMultiple(props.children)) return;
        if (swiperDivRef.current) {
            if (swiperRef.current) swiperRef.current.update();
            else swiperRef.current = new Swiper(swiperDivRef.current, props.config);
        } else {
            console.error("Failed to initialize Swiper, DOM is not ready");
        }
    }, [props.children, props.config]);

    if (!isMultiple(props.children)) return <>{props.children}</>;

    return (
        <div ref={swiperDivRef} id={props.name} className="swiper-container" style={props.style}>
            <div className="swiper-wrapper">{props.children}</div>
            {props.config?.pagination && <div className={`swiper-pagination ${props.name}-pagination`} />}
            {props.config?.navigation && <div className={`swiper-button-prev ${props.name}-nav-prev`} />}
            {props.config?.navigation && <div className={`swiper-button-next ${props.name}-nav-next`} />}
        </div>
    );
}

function isMultiple(children: React.ReactNode) {
    const childrenArray = children as React.ReactNodeArray;
    return childrenArray && childrenArray.length > 1;
}

interface CarousolSlideProps {
    id?: string;
    background?: string;
    backgroundSet?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent) => void | null;
    style?: React.CSSProperties;
    lazy?: boolean;
}

export const CarousolSlide: FunctionComponent<CarousolSlideProps> = (props) => {
    const classList = ["swiper-slide"];
    if (props.onClick) classList.push("clickable");
    return (
        <div id={props.id} className={classList.join(" ")} style={{ ...props.style }} onClick={props.onClick}>
            <img
                src={!props.lazy ? props.background : undefined}
                srcSet={!props.lazy ? props.backgroundSet : undefined}
                data-src={props.lazy ? props.background : undefined}
                data-srcset={props.lazy ? props.backgroundSet : undefined}
                className={props.lazy ? "swiper-lazy" : undefined}
                alt={"slide"}
                style={{ width: "100%" }}
            ></img>
            {props.lazy && <div className="swiper-lazy-preloader"></div>}
            {props.children}
        </div>
    );
};

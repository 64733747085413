import React, { FunctionComponent } from "react";

interface SectionProps {
    id?: string;
    children?: React.ReactNode;
    full?: Boolean;
    frame?: Boolean;
    padding?: Boolean;
    spa?: Boolean;
    form?: Boolean;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    noPointerEvents?: Boolean;
    noUserSelect?: Boolean;
    noContextMenu?: Boolean;
    className?: string;
    containerClassName?: string;
}

export const Section: FunctionComponent<SectionProps> = (props) => {
    let classList = ["section"];
    if (props.full) classList.push("full");
    if (props.frame) classList.push("frame");
    if (props.padding) classList.push("padding");
    if (props.noPointerEvents) classList.push("no-pointer-events");
    if (props.noUserSelect) classList.push("no-user-select");
    if (props.spa) classList.push("spa");
    if (props.form) classList.push("form");
    if (props.className) classList = [...classList, ...props.className.split(" ")];

    const onContextMenu = (e: React.MouseEvent) => {
        if (props.noContextMenu) e.preventDefault();
    };

    let containerClassList = ["section-container"];
    if (props.containerClassName) containerClassList = [...containerClassList, ...props.containerClassName.split(" ")];

    return (
        <div id={props.id} className={containerClassList.join(" ")} onContextMenu={onContextMenu} style={props.containerStyle}>
            <div className={classList.join(" ")} style={props.style}>
                {props.children}
            </div>
        </div>
    );
};

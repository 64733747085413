import { combineReducers } from "redux";
import LoggingReducer from "./loggingReducer";
import AccountReducer from "./accountReducer";
import PreferenceReducer from "./preferenceReducer";

export type RootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
    loggingReducer: LoggingReducer,
    account: AccountReducer,
    preference: PreferenceReducer,
});

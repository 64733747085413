import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";

import "./index.scss";
import { Section } from "../../Components/Section";
import Template from "../../Template";
import { post, SearchQuery, keyToLabel } from "../../utils";
// import { FullLoginPage } from "../../Components/FullLoginPage";
// import { Showcase } from "../../Components/Showcase";
import { ShowcaseItem, FilterWithOptions, FirstFilterChangeEvent, Option } from "../../Components/Showcase/types";

// import Modal from "../../Components/Modal";
// import SampleEdit from "./SampleEdit";
// import SampleDetailInner from "./SampleDetailInner";

export interface Sample {
    _id: string;
    po: string;
    gender: string;
    cat: string;
    content?: string[][];
    gauge?: string;
    fabric: string;
    tags?: string[];
    images?: string[];
    remark?: string;
    time_create: string;
    time_modify: string;
}

interface SampleParamType {
    query: string;
}

export default function Samples() {
    const { query } = useParams<SampleParamType>();
    const account = useSelector((state: RootState) => state.account);

    const [searchQuery, setSearchQuery] = useState<SearchQuery>();
    const [searching, setSearching] = useState<boolean>(false);
    const [data, setData] = useState<{ [_id: string]: Sample }>({});
    const [showcaseItems, setShowcaseItems] = useState<ShowcaseItem[]>([]);
    const [tags, setTags] = useState<{ key: string; freq: number }[]>([]);
    const history = useHistory();

    const [addingNew, setAddingNew] = useState<boolean>(false);
    const [displayingItem, setDisplayingItem] = useState<string | null>(null);
    const [editingItem, setEditingItem] = useState<string | null>(null);

    useEffect(() => {
        if (!account.user) return;
        try {
            const searchQuery = new SearchQuery(query);
            setSearchQuery(searchQuery);
        } catch (e) {
            console.error("Invalid Search Query: ", query);
            // TODO: Better Error Handling
            history.push(" "); // Redirect to empty query
        }
    }, [query, history, account.user]);

    useEffect(() => {
        if (!searchQuery) return;
        setSearching(true);
        post("samples/search", {
            query: searchQuery.getQueries(),
            mode: "coarse"
        }).then(
            (res) => {
                setSearching(false);
                if (!res.data || res.data.length === 0) return;
                const samples = res.data as Sample[];
                const sampleShowcaseItems: ShowcaseItem[] = [];
                const sampleMap: { [_id: string]: Sample } = {};

                samples.forEach((sample) => {
                    sampleMap[sample._id] = sample;
                    sampleShowcaseItems.push({
                        _id: sample._id,
                        images: sample.images,
                        cat: sample.cat,
                        tags: sample.tags,
                        po: sample.po,
                        fabric: sample.fabric,
                        time_create: sample.time_create,
                        time_update: sample.time_modify,
                        cardProps: {
                            title: sample.po,
                            titleRight: keyToLabel(sample.cat),
                            subtitle: `${keyToLabel(sample.fabric || "")}`
                        }
                    });
                });
                setData(sampleMap);
                setShowcaseItems(sampleShowcaseItems);
            },
            (err) => {
                setSearching(false);
                console.warn(err.response.data);
                // TODO: Better Error Handling
                Promise.resolve(err);
            }
        );
    }, [searchQuery]);

    useEffect(() => {
        if (!data || Object.keys(data).length === 0) return;
        const tagMap: { [key: string]: { key: string; freq: number } } = {};
        for (const _id of Object.keys(data)) {
            const item = data[_id];
            if (item.tags && item.tags.length > 0) {
                for (const tag of item.tags) {
                    if (tagMap[tag]) tagMap[tag].freq += 1;
                    else tagMap[tag] = { key: tag, freq: 1 };
                }
            }
        }
        const tagsArray = Object.values(tagMap);
        tagsArray.sort((a, b) => b.freq - a.freq);
        setTags(tagsArray);
    }, [data]);

    // Access Control
    // if (!localStorage.getItem("accessToken")) {
    //     return <FullLoginPage />;
    // } else if (!account.user || account.user.role !== "admin") {
    //     return (
    //         <Template>
    //             <Section padding>
    //                 <h1>Comming soon</h1>
    //             </Section>
    //         </Template>
    //     );
    // }

    return (
        <Template>
            <Section padding>
                <h1>Comming soon</h1>
            </Section>
        </Template>
    );

    // const filterOnChange = ({ event, key, option }: FirstFilterChangeEvent) => {
    //     if (!searchQuery) return;
    //     const query = searchQuery.getQueries();

    //     if (!query[key]) {
    //         query[key] = [];
    //     }

    //     // Multiple first filter solution

    //     // const index = query[key].indexOf(option);
    //     // if (index !== -1) {
    //     //     query[key].splice(index, 1);
    //     //     if (query[key].length === 0) delete query[key];
    //     // } else if (!query[key]) {
    //     //     query[key] = [option];
    //     // } else {
    //     //     query[key].push(option);
    //     // }

    //     // Single first filter solution
    //     const index = query[key].indexOf(option);
    //     if (index === -1) query[key] = [option];

    //     history.replace(searchQuery.toString());
    // };

    // const getFirstFilters = (): FilterWithOptions[] => {
    //     const q = searchQuery && searchQuery.getQueries() ? searchQuery.getQueries() : null;
    //     let firstFileters: FilterWithOptions[] = [];
    //     if (!q) {
    //         return firstFileters;
    //     }

    //     if (q["gender"] && q["gender"][0] === "women") {
    //         firstFileters = [
    //             ...firstFileters,
    //             {
    //                 key: "cat",
    //                 options: constructOptions([
    //                     "pullover",
    //                     "jacket",
    //                     "cardigan",
    //                     "heavy_gauge",
    //                     "dress",
    //                     "pants",
    //                     "legging",
    //                     "skirt"
    //                 ]),
    //                 value: q ? q["cat"] : []
    //             },
    //             {
    //                 key: "fabric",
    //                 options: constructOptions(["flat", "circular"]),
    //                 value: q ? q["fabric"] : []
    //             }
    //         ];
    //     } else if (q["gender"] && q["gender"][0] === "men") {
    //         firstFileters = [
    //             ...firstFileters,
    //             {
    //                 key: "cat",
    //                 options: constructOptions(["pullover", "jacket", "bottom"]),
    //                 value: q ? q["cat"] : []
    //             },
    //             {
    //                 key: "fabric",
    //                 options: constructOptions(["flat", "circular"]),
    //                 value: q ? q["fabric"] : []
    //             }
    //         ];
    //     }
    //     return firstFileters;
    // };

    // const constructOptions = (keys: string[]): Option[] => {
    //     const res: Option[] = [];
    //     keys.forEach((key) => res.push({ key }));
    //     return res;
    // };

    // const getSecondFilters = (): FilterWithOptions[] => {
    //     return [
    //         {
    //             key: "tags",
    //             options: tags,
    //             value: ""
    //         }
    //     ];
    // };

    // return (
    //     <Template>
    //         <Section full frame padding>
    //             <Showcase
    //                 id="sample-showcase"
    //                 title="Samples"
    //                 resourceType="samples"
    //                 items={showcaseItems}
    //                 searching={searching}
    //                 firstFilters={getFirstFilters()}
    //                 secondFilters={getSecondFilters()}
    //                 firstFilterOnChange={filterOnChange}
    //                 onAddNew={() => setAddingNew(true)}
    //                 onItemClick={(item) => setDisplayingItem(item._id)}
    //                 onItemEdit={(item) => setEditingItem(item._id)}
    //                 gridConfig={{
    //                     columns: 4,
    //                     imageRatio: 1.33
    //                 }}
    //                 searchQueryFields={["po", "cat", "tags", "fabric"]}
    //                 searchQueryThreshold={0.2}
    //             />
    //         </Section>
    //         {addingNew && <SampleEdit onClose={() => setAddingNew(false)} />}
    //         {displayingItem && (
    //             <Modal
    //                 id={`sample-quick-view-${displayingItem}`}
    //                 backdrop
    //                 backdropClose
    //                 escClose
    //                 onClose={() => {}}
    //                 afterClose={() => setDisplayingItem(null)}
    //                 className="showcase-item-quick-view-modal"
    //             >
    //                 <SampleDetailInner
    //                     id={displayingItem}
    //                     sample={data[displayingItem]}
    //                     onClose={() => {}}
    //                     afterClose={() => setDisplayingItem(null)}
    //                 />
    //             </Modal>
    //         )}
    //         {editingItem && <SampleEdit po={data[editingItem].po} onClose={() => setEditingItem(null)} />}
    //     </Template>
    // );
}

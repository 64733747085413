import React, { useEffect, useRef } from "react";
import Portal from "../Components/Portal";
import Overlay from "../Components/Overlay";
import { animate } from "../utils";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

interface IModalProps {
    id: string;
    backdrop?: boolean;
    backdropClose?: boolean;
    escClose?: boolean;
    close?: Function; // Same as afterClose
    afterClose?: Function; // Throw after the modal animation finished
    onClose?: Function; // Throw when the modal close is triggered
    titleBar?: React.ReactNode;
    children: React.ReactNode;
    inline?: boolean;
    className?: string;
    style?: React.CSSProperties;
    autoFocusId?: string;
}

export default function Modal(props: IModalProps) {
    const modal = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (props.autoFocusId) {
            const els = document.querySelectorAll(`#${props.autoFocusId}`);
            if (els.length > 0) {
                const el = els[els.length - 1] as HTMLElement;
                el.focus();
            }
        }
    }, [props.autoFocusId]);

    useEffect(() => {
        const currentModal = modal.current;
        if (currentModal !== null) {
            disableBodyScroll(currentModal);
        }

        return () => {
            if (currentModal !== null) {
                enableBodyScroll(currentModal);
            }
        };
    }, []);

    useEffect(() => {
        if (!props.escClose) return;
        const keyPressListener = (e: KeyboardEvent) => {
            if (e.keyCode === 27) {
                animate(props.id, "animated fadeOut faster", { callBack: props.afterClose || props.close });
                if (props.onClose) props.onClose();
            }
        };
        document.addEventListener("keydown", keyPressListener);
        return () => {
            document.removeEventListener("keydown", keyPressListener);
        };
    }, [props]);

    const renderInner = () => {
        return (
            <div className="modal-inline">
                {props.titleBar && (
                    <div className="modal-titlebar">
                        <div>
                            <h1>{props.titleBar}</h1>
                        </div>
                        {!props.inline && (
                            <button className="circle" onClick={onClose}>
                                ×
                            </button>
                        )}
                    </div>
                )}
                {props.children}
            </div>
        );
    };

    const onClose = (e?: React.SyntheticEvent) => {
        if (e) e.stopPropagation();
        animate(props.id, "animated fadeOut faster", { callBack: props.afterClose || props.close });
        if (props.onClose) props.onClose();
    };

    if (props.inline) {
        return renderInner();
    }
    return (
        <Portal container="#root">
            <Overlay id={props.id} backdrop={props.backdrop} backdropClick={props.backdropClose ? () => onClose() : () => {}}>
                <div
                    ref={modal}
                    id={`${props.id}-inner`}
                    className={`modal ${props.className}` || "modal md"}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    style={props.style}
                    onScroll={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {renderInner()}
                </div>
            </Overlay>
        </Portal>
    );
}

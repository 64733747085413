import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";
import { ShowcaseCardProps } from "./types";
import { oss, imageOnError } from "../../utils";
import Config from "../../Config/config.json";

export function ShowcaseCard(props: ShowcaseCardProps) {
    const account = useSelector((state: RootState) => state.account);

    const { item } = props;

    const renderTags = () => {
        const renderedTags = [];
        if (account.user && account.user.role === "admin") {
            renderedTags.push(
                <div
                    key={`item-tag-edit`}
                    className={`showcase-card-tag edit`}
                    onClick={(e) => {
                        e.stopPropagation();
                        props.onItemEdit(item);
                    }}
                >
                    编辑
                </div>
            );
        }
        if (!props.item.tags) return renderedTags;
        for (const tag of props.item.tags) {
            if (Config.renderableTags.includes(tag)) {
                renderedTags.push(
                    <div key={`item-tag-${tag}`} className={`showcase-card-tag ${tag}`}>
                        {tag.replace(/[_-]/, " ")}
                    </div>
                );
            }
        }
        return <>{renderedTags}</>;
    };

    const renderFav = () => {
        if (props.item.isFavorite) {
            return (
                <div className="showcase-card-fav active">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" fill="#191919">
                        <path d="M15.765,2.434l2.875,8.512l8.983,0.104c0.773,0.009,1.093,0.994,0.473,1.455l-7.207,5.364l2.677,8.576 c0.23,0.738-0.607,1.346-1.238,0.899L15,22.147l-7.329,5.196c-0.63,0.447-1.468-0.162-1.238-0.899l2.677-8.576l-7.207-5.364 c-0.62-0.461-0.3-1.446,0.473-1.455l8.983-0.104l2.875-8.512C14.482,1.701,15.518,1.701,15.765,2.434z" />
                    </svg>
                </div>
            );
        } else {
            // TODO: Currently don't show the fav icon when not saved
            return null;
        }
    };

    const getImagePath = () => {
        const { images } = item;
        if (images && images.length > 0) {
            const ossPath = oss(images[0], { style: "sample_grid_item" });
            return ossPath;
        } else return null;
    };

    const imageOnLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const imageEl = e.target as HTMLElement;
        imageEl.style.opacity = "1";
    };

    const isGuest = !account.user || account.user.name === "guest";

    return (
        <div
            id={`showcase-card-${item.po}`}
            className="showcase-card"
            key={item.po}
            onClick={() => props.onItemClick(item)}
            style={props.style}
        >
            <div className="image-holder">
                <div className="image-holder-inner">
                    <img src={getImagePath() || ""} alt={item.po} onError={imageOnError} onLoad={imageOnLoad} />
                </div>
            </div>
            <div className="label-holder">
                <div className="first-line">
                    <div>{item.cardProps.title}</div>
                    <div className="first-line-extra">{item.cardProps.titleRight}</div>
                </div>
                <div className="second-line">
                    <div>{item.cardProps.subtitle}</div>
                    <div className="second-line-extra">{item.cardProps.secondLineExtra}</div>
                </div>
            </div>
            <div className="tags-holder">{renderTags()}</div>
            {!isGuest && <div className="fav-holder">{renderFav()}</div>}
        </div>
    );
}

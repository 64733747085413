import React, { useState, ChangeEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../Reducers";
import { post } from "../utils";

interface ICommentSectionProps {
    resourceType: string;
    id?: string;
    header?: string;
    secondaryButton?: React.ReactNode;
    placeholder?: string;
    metadata?: { [key: string]: string };
}

interface ICommentSectionStates {
    processing: boolean;
    guestEmail: string;
    comments: string;
    status: string;
}

export function CommentSection(props: ICommentSectionProps) {
    const MAX_CHAR = 300;

    const [state, setState] = useState<ICommentSectionStates>({
        processing: false,
        guestEmail: localStorage.getItem("guestEmail") || "",
        comments: props.id ? localStorage.getItem(`comment_${props.resourceType}_${props.id}`) || "" : "",
        status: ""
    });

    const account = useSelector((state: RootState) => state.account);

    // Guest cannot sent comment at this time.
    const isGuest = !account.user || account.user.name === "guest";

    const commentOnChange = (e: ChangeEvent) => {
        const el = e.target as HTMLInputElement;
        el.style["height"] = el.scrollHeight + "px";
        if (props.id) localStorage.setItem(`comment_${props.resourceType}_${props.id}`, el.value);
        setState({ ...state, comments: el.value });
    };

    const guestEmailOnChange = (e: ChangeEvent) => {
        const el = e.target as HTMLInputElement;
        localStorage.setItem("guestEmail", el.value);
        setState({ ...state, guestEmail: el.value });
    };

    const sendComment = () => {
        if (isGuest && (!state.guestEmail || state.guestEmail.length === 0)) {
            alert("Please put your email address");
            return;
        }
        if (!state.comments || state.comments.length === 0) {
            alert("Empty message cannot be sent.");
            return;
        } else if (state.comments.length > MAX_CHAR) {
            alert("Message is too long to send.");
            return;
        }

        setState({ ...state, processing: true });

        post("services/comment", {
            resourceType: props.resourceType,
            id: props.id,
            guestEmail: state.guestEmail,
            message: state.comments,
            ...props.metadata
        }).then(
            (res) => {
                if (res.status === 200) {
                    localStorage.removeItem(`comment_${props.resourceType}_${props.id}`);
                    setState({ ...state, processing: false, comments: "", status: "Thank you! Comment sent." });
                } else {
                    console.error("Comment failed to send", res);
                    setState({ ...state, processing: false, status: "Failed to send message." });
                }
            },
            (err) => {
                console.error("Comment failed to send", err);
                setState({ ...state, processing: false, status: "Failed to send message." });
            }
        );
    };

    const { comments, guestEmail, processing } = state;

    const buttonDisabled =
        !comments ||
        comments.length === 0 ||
        comments.length > MAX_CHAR ||
        processing ||
        (isGuest &&
            (!state.guestEmail ||
                !new RegExp("^([a-zA-Z0-9_\\-\\.]{1,100})@([a-zA-Z0-9_\\-\\.]{1,100})\\.([a-zA-Z]{2,5})$").test(
                    state.guestEmail
                )));

    const buttonText = processing ? "Sending..." : "Send";

    return (
        <div className="comment-section">
            {props.header && <h3 style={{ whiteSpace: "pre-wrap" }}>{props.header}</h3>}
            <div className="input-field no-margin">
                {isGuest && (
                    <input onChange={guestEmailOnChange} value={guestEmail} placeholder="Your Email Address"></input>
                )}
                {isGuest && <br />}
                <textarea
                    onChange={commentOnChange}
                    value={comments}
                    placeholder={props.placeholder || "Comments are private. Only you and Trumode can see them."}
                />
                <div className="button-group">
                    <button className="full" onClick={sendComment} disabled={buttonDisabled}>
                        {buttonText}
                    </button>
                    {props.secondaryButton}
                </div>
                {comments && comments.length > MAX_CHAR && (
                    <div className="helper-text error">
                        Character count exceed the limit. (Current: {comments.length}, Limit: {MAX_CHAR})
                    </div>
                )}
                {state.status && <div className="helper-text">{state.status}</div>}
            </div>
        </div>
    );
}

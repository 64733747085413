import React from "react";

import { Link } from "react-router-dom";

export interface IMenuItem {
    name: string;
    link?: string;
    class?: string;
    submenu?: IMenuItem[];
    noTitle?: boolean;
    title?: string;
}

interface IMultiLevelMenuProps {
    menu?: IMenuItem;
    deep?: number;
    baseClass?: string;
    callback?: Function;
    name?: string;
}

export default function MultiLevelMenu({ menu = undefined, deep = -2, baseClass = undefined, callback = undefined, name = undefined }: IMultiLevelMenuProps) {
    if (!menu || deep === -1) {
        return null;
    }

    let renderedSubmenu = [];
    if (menu.submenu) {
        for (let submenu of menu.submenu) {
            const nextName = name === undefined ? submenu.name : name + "-" + submenu.name;
            renderedSubmenu.push(
                <MultiLevelMenu key={submenu.name} menu={submenu} deep={deep - 1} baseClass={baseClass} callback={callback} name={nextName} />
            );
        }
    }

    let classList: string[] = [];
    if (baseClass) classList.push(baseClass);
    if (Array.isArray(menu.class)) {
        classList = [...classList, ...menu.class];
    } else if (typeof menu.class === "string") {
        classList = [...classList, menu.class];
    }

    return (
        <div id={name} className={classList.join(" ")}>
            {!menu.noTitle ? menu.link ? <Link to={menu.link}>{menu.title}</Link> : <Link to={"#"}>{menu.title}</Link> : null}
            {deep !== 0 && renderedSubmenu.length > 0 ? <nav>{renderedSubmenu}</nav> : null}
        </div>
    );
}

export function GetMenuByPath(menu: IMenuItem, path: string): IMenuItem | null {
    let current = menu;
    for (let part of path.split(".")) {
        let flag = false;
        if (!current.submenu) return null;
        for (let sub of current.submenu) {
            if (sub.name === part) {
                current = sub;
                flag = true;
                break;
            }
        }
        if (!flag) {
            return null;
        }
    }
    return current;
}

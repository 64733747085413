import React, { useState, useEffect } from "react";

export function ScrollToTopButton() {
    const [showBtn, setShowBtn] = useState<boolean>(false);

    useEffect(() => {
        const listener = () => {
            if (window.scrollY > window.innerHeight / 2) {
                if (!showBtn) setShowBtn(true);
            } else {
                if (showBtn) setShowBtn(false);
            }
        };
        document.addEventListener("scroll", listener);
        return () => {
            document.removeEventListener("scroll", listener);
        };
    }, [showBtn]);

    const scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    };

    const classList = ["fab"];
    if (!showBtn) classList.push("hide");

    return (
        <div className={classList.join(" ")} onClick={scrollToTop}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                <path d="M 13 10.59375 L 21.765625 19.179688 C 22.15625 19.5625 22.78125 19.558594 23.171875 19.171875 L 24.707031 17.636719 C 25.097656 17.242188 25.097656 16.609375 24.703125 16.21875 L 13.707031 5.292969 C 13.511719 5.097656 13.257813 5 13 5 C 12.742188 5 12.488281 5.097656 12.292969 5.292969 L 1.296875 16.21875 C 0.902344 16.609375 0.902344 17.242188 1.292969 17.636719 L 2.828125 19.171875 C 3.21875 19.558594 3.84375 19.5625 4.234375 19.179688 Z" />
            </svg>
            TOP
        </div>
    );
}

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../Reducers";
import Modal from "../Modal";
import { animate } from "../../utils";
import { logoutAction } from "../../Actions";
import LoginModal from "./LoginModal";
import { useHistory } from "react-router";

export default function UserButton() {
    const account = useSelector((state: RootState) => state.account);
    const [showModal, setShowModal] = useState<boolean>(false);

    const history = useHistory();

    const dispatch = useDispatch();

    const logout = () => {
        animate("user-modal", "animated fadeOut faster", {
            callBack: () => {
                setShowModal(false);
                dispatch(logoutAction());
            }
        });
    };

    const changePassword = () => {
        animate("user-modal", "animated fadeOut faster", {
            callBack: () => {
                setShowModal(false);
                history.push("/password-reset");
            }
        });
    };

    // When no user, show the login button and login modal if click
    if (!account.user || account.user.role === "guest") {
        return (
            <>
                <button className="user-button" onClick={() => setShowModal(true)}>
                    Sign in
                </button>
                {showModal && <LoginModal id="login-modal" close={() => setShowModal(false)} />}
            </>
        );
    }

    // When the user is logged in, show the username and my account modal
    return (
        <>
            <button className="user-button" onClick={() => setShowModal(true)}>
                {account.user.username}
            </button>
            {showModal && (
                <Modal id="user-modal" backdrop backdropClose titleBar="Welcome" close={() => setShowModal(false)}>
                    <div>You are logined as {account.user.username}</div>
                    <div className="button-group full">
                        <button name="submit" className="input-field-item" onClick={changePassword}>
                            Change Password
                        </button>
                        <br />
                        <button name="submit" className="input-field-item" onClick={logout}>
                            Sign out
                        </button>
                    </div>
                </Modal>
            )}
        </>
    );
}

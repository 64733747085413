import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import { get, post, SERVER_STATUS } from "../../utils";
import Template from "../../Template";
import { Section } from "../../Components/Section";
import PasswordResetStep2Schema from "../../Schemas/password_reset_step_2.schema.json";
import Form, { IFormResult, FormResultStatus } from "../../Components/FinalForm";
import Loading from "../../Components/Loading/Loading";
import { Link } from "react-router-dom";

interface IPasswordResetProps {
    id: string;
}

interface IPasswordResetData {
    username: string;
    accessId: string;
}

export default function PasswordResetSubmitPage(props: RouteComponentProps<IPasswordResetProps>) {
    const [originalData, setOriginalData] = useState<IPasswordResetData | SERVER_STATUS.NOT_FOUND | SERVER_STATUS.NOT_START>(SERVER_STATUS.NOT_START);
    const [formData, setFormData] = useState({});
    const history = useHistory();

    useEffect(() => {
        get(`accounts/password-reset?accessId=${props.match.params.id}`).then(
            (res) => {
                if (res.data) {
                    setOriginalData(res.data);
                } else {
                    setOriginalData(SERVER_STATUS.NOT_FOUND);
                }
            },
            (err) => {
                if (err) {
                    Promise.resolve(err);
                    setOriginalData(SERVER_STATUS.NOT_FOUND);
                }
            }
        );
    }, [props.match.params.id]);

    const submit = async () => {
        let returnRes: IFormResult = {};

        await post("accounts/password-reset-submit", {
            accessId: props.match.params.id,
            data: formData,
        }).then(
            (res) => {
                if (res.status === 200) {
                    returnRes = {
                        status: FormResultStatus.SUCCESS,
                        clearInput: true,
                        message:
                            "Password Reset Successfully. Please remember your new password and keep it safe. We will redirect you to home page in 3 secs.",
                    };

                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("ossToken");

                    setTimeout(() => {
                        history.push("/");
                    }, 3000);
                } else {
                    returnRes = {
                        status: FormResultStatus.FAILURE,
                        clearInput: true,
                        message: res.data.clientMessage || "Password Reset Failed, please try again later.",
                    };
                }
            },
            (err) => {
                const message =
                    err.response && err.response.data && err.response.data.clientMessage
                        ? err.response.data.clientMessage
                        : "Password Reset Failed, please try again later.";
                if (err) {
                    returnRes = {
                        status: FormResultStatus.FAILURE,
                        clearInput: true,
                        message,
                    };
                }
            }
        );

        return returnRes;
    };

    if (originalData === SERVER_STATUS.NOT_START) {
        return (
            <Template>
                <Section full padding>
                    <Loading />
                </Section>
            </Template>
        );
    } else if (originalData === SERVER_STATUS.NOT_FOUND) {
        return (
            <Template>
                <Section full padding>
                    <h1>Sorry, this link is invalid</h1>
                    <p>We are unable to process your request. Either this link is invalid, timeout or has already been processed.</p>
                    <p>
                        If you still want to reset your password, you can resend the request <Link to="/reset-password">here</Link>
                    </p>
                </Section>
            </Template>
        );
    }

    return (
        <Template>
            <Section padding full containerStyle={{ background: "#eee" }}>
                <h1>Finish your password reset</h1>
                <p>You're almost there, think about a new password and remember it.</p>
            </Section>
            <Section padding form>
                <h3>Account</h3>
                <p>{originalData.username}</p>
                <Form
                    id="password_reset_step2"
                    schema={PasswordResetStep2Schema}
                    context={[formData, setFormData]}
                    actions={{
                        submit,
                    }}
                />
                <p className="caption">Use 8 or more characters with a mix of lowercase letters, uppercase letters and numbers</p>
            </Section>
        </Template>
    );
}

import * as ACTION from "../Actions/types";

export default function preferenceReducer(state = {}, action: ACTION.Action) {
    const { type, payload } = action;
    switch (type) {
        case ACTION.SAVE_ITEM_ACTION: {
            return { ...state, ...payload };
        }
    }

    return state;
}

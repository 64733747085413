import React, { useState, useEffect, ReactNode } from "react";
import Ajv, { ErrorObject } from "ajv";
import Select from "react-select";
import "./index.scss";
import { oss } from "../../utils";
import DropFile from "./DropFile";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

interface JSONSchema {
    type: string;
    pattern?: string;
    description?: string;
}

type ValidateType = ErrorObject[] | null | undefined;

const ajv = new Ajv({
    verbose: true,
    allErrors: true,
});

const updateSingleErrorInfo = (name: string, errors: ValidateType) => {
    let thisError: Ajv.ErrorObject | null = null;
    if (errors) {
        for (const error of errors) {
            const pathParts = error.dataPath.split(".");
            const errorName = pathParts[pathParts.length - 1];
            if (name === errorName) {
                thisError = error;
                break;
            }
        }
    }

    const inputEl = document.querySelector(`#${name}-input`) as HTMLElement;
    const helperEl = document.querySelector(`#${name}-helper`) as HTMLElement;

    if (thisError) {
        inputEl.classList.add("error");
        const parentSchema = thisError.parentSchema as JSONSchema;
        if (!helperEl) {
            return;
        } else if (!parentSchema.description) {
            helperEl.innerHTML = "This field is invalid";
        } else helperEl.innerHTML = parentSchema.description;
    } else {
        inputEl.classList.remove("error");
        if (helperEl) helperEl.innerHTML = "";
    }
};

const onFieldChange = (e: React.ChangeEvent, state: { [key: string]: any }, setState: Function) => {
    const el = e.target as HTMLInputElement;
    setState({
        ...state,
        [el.name]: el.value,
    });
};

interface IFormProps {
    id: string;
    schema: { [key: string]: any };
    context: [{ [key: string]: any }, Function];

    actions?: { [key: string]: Function };
    style?: React.CSSProperties;
    children?: React.ReactNode;
    options?: { [key: string]: IOption[] | Function };
    customized?: { [key: string]: (props: { errors: ValidateType; fieldProps?: IFieldProps }) => React.ReactNode };
    fastSwitch?: boolean;
}

export interface IOption {
    value: string | number;
    label: string;
}

export enum FormResultStatus {
    SUCCESS,
    FAILURE,
}

export interface IFormResult {
    status?: FormResultStatus;
    message?: string;
    clearInput?: boolean;
}

export default function Form(props: IFormProps) {
    const [errors, setErrors] = useState<ValidateType>([]);
    const [state, setState] = props.context;
    const [processing, setProcessing] = useState<boolean>(false);
    const [formResult, setFormResult] = useState<IFormResult>();

    useEffect(() => {
        const validator = ajv.compile(props.schema);
        validator(state);
        setErrors(validator.errors);
        // console.log(state);
        // console.log(validator.errors);
    }, [props.schema, props.context, state]);

    const updateAllErrorInfo = () => {
        // const allFields = document.querySelectorAll(".input-field-item");
        // allFields.forEach((el) => {
        //     updateSingleErrorInfo(el as HTMLInputElement, errors);
        // });
    };

    const onSubmit = async (customizedSubmit: Function) => {
        updateAllErrorInfo();
        if (errors !== null) {
            console.error(errors);
            return;
        }
        setProcessing(true);
        const res = (await customizedSubmit()) as IFormResult;
        setProcessing(false);

        if (!res) return;

        setFormResult(res);

        if (res.clearInput) {
            const fields = props.schema.properties;
            const initialState: { [key: string]: any } = {};
            for (const key of Object.keys(fields)) {
                initialState[key] = "";
            }
            setState(initialState);
        }
    };

    const renderFields = () => {
        const renderedFields = [];
        const fields = props.schema.properties;
        if (props.schema["#layout"]) {
            const renderedRows = [];
            for (const row of props.schema["#layout"].rows) {
                const renderedCols = [];
                for (const col of row) {
                    const key = col.key;

                    const customized = props.customized && props.customized[key] ? props.customized[key] : null;
                    const options = props.options && props.options[key] ? props.options[key] : null;

                    renderedCols.push(
                        <div key={`col-${renderedRows.length}-${renderedCols.length}`} className={`col col-${col.width || 12}`}>
                            <Field
                                key={key}
                                context={props.context}
                                errors={errors}
                                name={key}
                                field={fields[key]}
                                disalbed={processing}
                                options={options}
                                customized={customized ? (fieldProps) => customized({ errors, fieldProps }) : undefined}
                            />
                        </div>
                    );
                }
                renderedRows.push(
                    <div key={`row-${renderedRows.length}`} className="row">
                        {renderedCols}
                    </div>
                );
            }
            return <div className="layout-form">{renderedRows}</div>;
        } else {
            for (const key of Object.keys(fields)) {
                const customized = props.customized && props.customized[key] ? props.customized[key] : null;
                const options = props.options && props.options[key] ? props.options[key] : null;
                renderedFields.push(
                    <Field
                        key={key}
                        context={props.context}
                        errors={errors}
                        name={key}
                        field={fields[key]}
                        disalbed={processing}
                        options={options}
                        customized={customized ? (fieldProps) => customized({ errors, fieldProps }) : undefined}
                        fastSwitch={props.fastSwitch}
                    />
                );
            }
            return renderedFields;
        }
    };

    const renderActions = () => {
        const renderedActions = [];
        const actions = props.schema["#actions"];

        if (!props.actions) return null;
        for (const key of Object.keys(props.actions)) {
            const func = props.actions[key];
            const action = actions ? actions[key] : null;
            if (props.customized && props.customized[key]) {
                const actionEl: ReactNode = props.customized[key]({ errors });
                if (!actionEl) continue;
                renderedActions.push(actionEl);
            } else {
                renderedActions.push(
                    <button
                        key={key}
                        className={action ? action.className : null}
                        disabled={(action && action.needValid ? errors !== null : false) || processing}
                        onClick={key === "submit" ? () => onSubmit(func) : () => func()}
                    >
                        {action ? action.text : key}
                    </button>
                );
            }
        }
        return <div className="form-actions">{renderedActions}</div>;
    };

    const renderFormResult = () => {
        if (!formResult) return null;
        return <div className={`form-status ${formResult.status !== undefined ? FormResultStatus[formResult.status] : ""}`}>{formResult.message}</div>;
    };

    let classList = ["final-form"];
    if (props.schema["#className"]) classList = [...classList, ...props.schema["#className"].split(" ")];

    return (
        <div id={props.id} className={classList.join(" ")} style={props.style}>
            {renderFields()}
            {renderActions()}
            {renderFormResult()}
        </div>
    );
}

export interface IFieldProps {
    context: [{ [key: string]: any }, Function];
    errors: ValidateType;
    name: string;

    field: { [key: string]: any };
    disalbed?: boolean;
    options?: IOption[] | Function | null;
    customized?: ((props: IFieldProps) => React.ReactNode) | null;
    fastSwitch?: boolean;
}

const Field = (props: IFieldProps) => {
    const { field } = props;

    const renderInput = () => {
        if (props.customized) return props.customized(props);

        switch (field["#input-type"]) {
            case "number":
                return <NumberField {...props} />;
            case "checkbox":
            case "boolean": {
                return <BooleanField {...props} />;
            }
            case "textarea": {
                return <TextAreaField {...props} />;
            }
            case "select": {
                return <SelectField {...props} />;
            }
            case "files": {
                return <FilesSelectField {...props} />;
            }
            case "text":
            default:
                return <TextField {...props} />;
        }
    };

    return (
        <div className="input-field">
            {field["#header"] && <h3>{field["#header"]}</h3>}
            {renderInput()}
            {field["#helper"] && (
                <div id={`${props.name}-helper`} className="caption">
                    {field["#helper"]}
                </div>
            )}
            <div id={`${props.name}-helper`} className="helper-text error"></div>
        </div>
    );
};

const TextField = (props: IFieldProps) => {
    const [state, setState] = props.context;
    const { field } = props;

    return (
        <input
            id={`${props.name}-input`}
            name={props.name}
            disabled={props.disalbed}
            type={field["#input-type"] || "text"}
            className="input-field-item"
            placeholder={field["#placeholder"]}
            value={state[props.name]}
            onChange={(e) => onFieldChange(e, state, setState)}
            onBlur={() => updateSingleErrorInfo(props.name, props.errors)}
            onKeyDown={(e) => props.fastSwitch && formFastSwitch(e)}
            {...field["#attributes"]}
        />
    );
};

const TextAreaField = (props: IFieldProps) => {
    const [state, setState] = props.context;
    const { field } = props;

    // Auto Adjust the Textarea height
    const onTextareaChange = (e: React.ChangeEvent) => {
        const el = e.target as HTMLInputElement;
        el.style["height"] = el.scrollHeight + "px";
        onFieldChange(e, state, setState);
    };

    return (
        <textarea
            id={`${props.name}-input`}
            name={props.name}
            disabled={props.disalbed}
            className="input-field-item"
            placeholder={field["#placeholder"]}
            value={state[props.name]}
            onChange={onTextareaChange}
            onBlur={() => updateSingleErrorInfo(props.name, props.errors)}
        />
    );
};

// Ultilize react-select to render select component
const SelectField = (props: IFieldProps) => {
    const [state, setState] = props.context;
    const { field } = props;

    let options = [];
    if (!props.options) {
        console.error("No options provided");
        return <Select id={`${props.name}-input`} className="input-field-item" classNamePrefix="ifi" name={props.name} isDisabled={true} options={[]} />;
    } else if (props.options instanceof Function) {
        options = props.options();
    } else {
        options = props.options as IOption[];
    }

    const isMulti = field["#isMulti"];

    const onSelectChange = (value: any) => {
        if (isMulti) {
            const computedValue = [];
            for (const v of value) {
                computedValue.push(v.value);
            }
            setState({
                ...state,
                [props.name]: computedValue,
            });
        } else {
            setState({
                ...state,
                [props.name]: value.value,
            });
        }
    };

    let currentValue = null;
    for (const option of options) {
        if (option.value === state[props.name]) {
            currentValue = option;
            break;
        }
    }

    return (
        <Select
            id={`${props.name}-input`}
            isMulti={isMulti}
            className="input-field-item"
            classNamePrefix="ifi"
            name={props.name}
            isDisabled={props.disalbed}
            options={options}
            placeholder={field["#placeholder"] || "请选择..."}
            value={currentValue}
            onChange={onSelectChange}
            onBlur={() => updateSingleErrorInfo(props.name, props.errors)}
        />
    );
};

const FilesSelectField = (props: IFieldProps) => {
    const [state, setState] = props.context;

    const [newFileSet, setNewFileSet] = useState<Set<string>>(new Set());

    const openFile = (e: React.MouseEvent, file: string) => {
        e.preventDefault();
        e.stopPropagation();
        if (isNew(file)) {
            alert("暂不支持预览新文件");
        } else {
            const url = oss(file as string, { style: "full_carousol_desk" });
            window.open(url, "_blank");
        }
    };

    const isNew = (item: string): boolean => {
        return newFileSet.has(item);
    };

    const getLabel = (item: string): string => {
        if (isNew(item)) {
            return `${item} - 新文件 (等待上传)`;
        } else {
            return item;
        }
    };

    const deleteFile = (e: React.MouseEvent, filename: string) => {
        e.stopPropagation();
        if (newFileSet.has(filename)) {
            const newFiles = state["$files"][props.name];
            for (let i = 0; i < newFiles.length; i++) {
                if (newFiles[i].name === filename) {
                    newFiles.splice(i, 1);
                    break;
                }
            }
            const oldFiles = state[props.name];
            for (let i = 0; i < oldFiles.length; i++) {
                if (oldFiles[i] === filename) {
                    oldFiles.splice(i, 1);
                    break;
                }
            }
            setState({ ...state });

            newFileSet.delete(filename);
            setNewFileSet(newFileSet);
        } else {
            const oldFiles = state[props.name];
            for (let i = 0; i < oldFiles.length; i++) {
                if (oldFiles[i] === filename) {
                    oldFiles.splice(i, 1);
                    break;
                }
            }

            if (!state["$files_delete"]) state["$files_delete"] = [];
            state["$files_delete"].push(filename);
            setState({ ...state });
        }
    };

    const FileContainer = SortableElement(({ item }: { item: string }) => (
        <div className={`file-holder ${isNew(item) ? "new-file" : ""}`} onDoubleClick={(e) => openFile(e, item)}>
            <div className="h-flex js ac">
                <div className="svg-holder">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#191919">
                        <path
                            d="M13.172,2H6C4.9,2,4,2.9,4,4v16c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V8.828c0-0.53-0.211-1.039-0.586-1.414l-4.828-4.828 C14.211,2.211,13.702,2,13.172,2z M18.5,9H13V3.5L18.5,9z"
                            fill="#191919"
                        />
                    </svg>
                </div>
                <span>{getLabel(item)}</span>
            </div>
            <div className="file-actions">
                <button className="circle sm" onClick={(e) => deleteFile(e, item)}>
                    ×
                </button>
            </div>
        </div>
    ));

    const FileList = SortableContainer(({ items }: { items: string[] }) => {
        if (!items || items.length === 0) return <div className="empty-file-list">拖拽文件到这里</div>;
        return (
            <div style={{ width: "100%" }}>
                {items.map((filename, index) => (
                    <FileContainer key={`item-${filename}`} index={index} item={filename} />
                ))}
            </div>
        );
    });

    const onSortStart = () => {
        document.body.style.cursor = "grabbing";
    };
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        document.body.style.cursor = "unset";
        arrayMove(state[props.name], oldIndex, newIndex);
        setState({ ...state });
    };

    const arrayMove = (array: any[], oldIndex: number, newIndex: number) => {
        const temp = array[oldIndex];
        const diff = oldIndex > newIndex ? -1 : 1;
        for (let i = oldIndex; i !== newIndex; i += diff) {
            array[i] = array[i + diff];
        }
        array[newIndex] = temp;
    };

    const addFiles = (files: FileList) => {
        for (const file of Array.from(files)) {
            if (!state["$files"]) state["$files"] = { [props.name]: [] };
            state["$files"][props.name].push(file);

            const fullFilename = (props.field["#prefix"] || "") + file.name;
            if (!state[props.name]) state[props.name] = [];
            state[props.name].push(fullFilename);
            newFileSet.add(fullFilename);
        }
        setState({ ...state });
        setNewFileSet(newFileSet);
    };

    return (
        <DropFile className="form-drop" handleDrop={addFiles}>
            <FileList items={state[props.name]} onSortStart={onSortStart} onSortEnd={onSortEnd} pressDelay={100} helperClass="grabbing" />
        </DropFile>
    );
};

const NumberField = (props: IFieldProps) => {
    const [state, setState] = props.context;
    const { field } = props;

    const updateNumberField = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value === "" || value === "0") {
            setState({
                ...state,
                [props.name]: 0,
            });
        } else if (parseInt(value)) {
            setState({
                ...state,
                [props.name]: parseInt(value),
            });
        }
    };

    return (
        <input
            id={`${props.name}-input`}
            name={props.name}
            disabled={props.disalbed}
            type="text"
            className="input-field-item"
            placeholder={field["#placeholder"]}
            value={state[props.name]}
            onChange={(e) => updateNumberField(e)}
            onBlur={() => updateSingleErrorInfo(props.name, props.errors)}
            onKeyDown={(e) => props.fastSwitch && formFastSwitch(e)}
        />
    );
};

const BooleanField = (props: IFieldProps) => {
    const [state, setState] = props.context;
    const { field } = props;

    const toggleField = () => {
        setState({
            ...state,
            [props.name]: !state[props.name],
        });
    };

    return (
        <input
            id={`${props.name}-input`}
            name={props.name}
            disabled={props.disalbed}
            type="checkbox"
            className="input-field-item input-field-checkbox"
            placeholder={field["#placeholder"]}
            checked={state[props.name] === true}
            onChange={() => toggleField()}
            onBlur={() => updateSingleErrorInfo(props.name, props.errors)}
            onKeyDown={(e) => props.fastSwitch && formFastSwitch(e)}
        />
    );
};

const formFastSwitch = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
        e.preventDefault();
        const elements = document.getElementsByClassName("input-field-item");
        for (let i = 0; i < elements.length - 1; i++) {
            if (e.target === elements[i]) {
                const next = elements[i + 1] as HTMLInputElement;
                if (next.type === "submit") {
                    next.click();
                } else next.focus();
                break;
            }
        }
    }
};

import React from "react";
import { Sample } from ".";
import Carousol, { CarousolSlide } from "../../Components/Carousol";
import { oss, animate, R, keyToLabel } from "../../utils";
import Config from "../../Config/config.json";
import { CommentSection } from "../../Components/CommentSection";

interface SampleDetailInnerProps {
    id: string;
    sample: Sample;
    onClose?: Function;
    afterClose?: Function;
    imageStyle?: string;
}

export default function SampleDetailInner(props: SampleDetailInnerProps) {
    const { sample } = props;

    const renderSlides = () => {
        const renderedSlides = [];
        for (let image of sample.images || []) {
            renderedSlides.push(<CarousolSlide key={image} background={oss(image, { style: props.imageStyle || "sample_grid_item" })} />);
        }

        return (
            <Carousol
                name="showcase-item-detail-inner-carousol"
                config={{
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                        renderBullet: (index: number, className: string) =>
                            `<div class="${className}"><img src="${oss(sample && sample.images ? sample.images[index] : "", {
                                style: "sample_grid_item",
                            })}" /></div>`,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    loop: true,
                }}
            >
                {renderedSlides}
            </Carousol>
        );
    };

    const onCloseButtonClick = () => {
        if (props.afterClose) animate(`sample-quick-view-${sample.po}`, "animated fadeOut faster", { callBack: props.afterClose });
        if (props.onClose) props.onClose();
    };

    const renderContents = (contents: string[][]) => {
        try {
            const renderedContents = [];
            contents.sort((a: string[], b: string[]) => parseInt(b[1]) - parseInt(a[1]));
            for (const content of contents) {
                const ecoFlag = Config.ecoFriendlyContent.indexOf(content[0]) !== -1;
                renderedContents.push(
                    <div key={content[1] + content[0]} className="sdi-content">
                        {content[1] > "0" && <div className="content-present">{content[1]}%</div>}
                        <div className={`content-name ${ecoFlag ? "eco" : ""}`}>
                            {R("content") && R("content").content && R("content").content[content[0]]
                                ? R("content").content[content[0]].label_en
                                : keyToLabel(content[0])}
                        </div>
                    </div>
                );
            }
            return (
                <div className="mt4">
                    <h3>Content</h3>
                    {renderedContents}
                </div>
            );
        } catch (e) {
            console.error("Content for PO#:" + sample.po + " is not valid");
            console.error(e);
        }
    };

    const renderTags = () => {
        const renderedTags = [];
        if (!sample.tags) return null;
        for (const tag of sample.tags) {
            renderedTags.push(
                <div key={`sample-tag-${tag}`} className={`showcase-card-tag ${tag}`}>
                    {tag.replace(/[_-]/, " ")}
                </div>
            );
        }
        return <div className="sdi-tag-container">{renderedTags}</div>;
    };

    return (
        <div className="showcase-item-detail-inner">
            <div className="sdi-image-container">{renderSlides()}</div>
            <div className="sdi-main">
                <div className="modal-titlebar">
                    <div>
                        <h1>{[sample.gender, sample.fabric, sample.cat].join(" ")}</h1>
                        <span>{`PO#: ${props.sample.po}`}</span>
                        {renderTags()}
                    </div>
                    {props.onClose && (
                        <button className="circle" onClick={onCloseButtonClick}>
                            ×
                        </button>
                    )}
                </div>
                {sample.content && renderContents(sample.content)}
                {sample.gauge && (
                    <div className="info-field mt4">
                        <h3>Gauge</h3>
                        <span>{sample.gauge}</span>
                    </div>
                )}
                {sample.remark && (
                    <div className="info-field mt4">
                        <h3>Remark</h3>
                        <span>{sample.remark}</span>
                    </div>
                )}
                <div className="mt4">
                    <CommentSection resourceType="sample" id={sample.po} header={"Leave your comments and requests about this style"} />
                </div>
            </div>
        </div>
    );
}

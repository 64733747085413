import React from "react";

interface IOverlayProps {
    id?: string;
    children: React.ReactNode;
    backdrop?: boolean;
    backdropClick?: (e: React.SyntheticEvent) => void;
}

export default function Overlay(props: IOverlayProps) {
    // Overlay / Backdrop
    const overlayClassList = ["overlay", "animated", "fadeIn"];
    if (props.backdrop) {
        overlayClassList.push("backdrop");
    }

    const onClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
        if (props.backdropClick) props.backdropClick(e);
    };

    return (
        <div id={props.id} className={overlayClassList.join(" ")} onClick={onClick}>
            {props.children}
        </div>
    );
}

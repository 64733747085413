export default {
    name: "main_menu",
    title: "Menu",
    noTitle: true,
    class: "main-menu",
    submenu: [
        // {
        //     name: "women",
        //     title: "Women",
        //     link: "/samples/q/gender:women",
        //     class: "lg-cols",
        //     submenu: [
        //         {
        //             name: "flat",
        //             title: "Flat Knit",
        //             link: "/samples/q/gender:women,fabric:flat",
        //             class: "sm-rows",
        //             submenu: [
        //                 {
        //                     name: "pullover",
        //                     title: "Pullover",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:pullover",
        //                 },
        //                 {
        //                     name: "cardigan",
        //                     title: "Cardigan",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:cardigan",
        //                 },
        //                 {
        //                     name: "heavy_gauge",
        //                     title: "Heavy Guage",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:heavy_gauge",
        //                 },
        //                 {
        //                     name: "dress",
        //                     title: "Dress",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:dress",
        //                 },
        //                 {
        //                     name: "pants",
        //                     title: "Pants",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:pants",
        //                 },
        //                 {
        //                     name: "legging",
        //                     title: "Legging",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:legging",
        //                 },
        //                 {
        //                     name: "skirt",
        //                     title: "Skirt",
        //                     link: "/samples/q/gender:women,fabric:flat,cat:skirt",
        //                 },
        //             ],
        //         },
        //         {
        //             name: "circular",
        //             title: "Circular",
        //             link: "/samples/q/gender:women,fabric:circular",
        //             class: "sm-rows",
        //             submenu: [
        //                 {
        //                     name: "pullover",
        //                     title: "Pullover",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:pullover",
        //                 },
        //                 {
        //                     name: "cardigan",
        //                     title: "Cardigan",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:cardigan",
        //                 },
        //                 {
        //                     name: "heavy_gauge",
        //                     title: "Heavy Guage",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:heavy_gauge",
        //                 },
        //                 {
        //                     name: "dress",
        //                     title: "Dress",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:dress",
        //                 },
        //                 {
        //                     name: "pants",
        //                     title: "Pants",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:pants",
        //                 },
        //                 {
        //                     name: "legging",
        //                     title: "Legging",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:legging",
        //                 },
        //                 {
        //                     name: "skirt",
        //                     title: "Skirt",
        //                     link: "/samples/q/gender:women,fabric:circular,cat:skirt",
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     name: "men",
        //     title: "Men",
        //     link: "/samples/q/gender:men",
        //     class: "lg-cols",
        //     submenu: [
        //         {
        //             name: "flat",
        //             title: "Flat Knit",
        //             link: "/samples/q/gender:men,fabric:flat",
        //             class: "sm-rows",
        //             submenu: [
        //                 {
        //                     name: "jacket",
        //                     title: "Jackets",
        //                     link: "/samples/q/gender:men,fabric:flat,cat:jacket",
        //                 },
        //                 {
        //                     name: "pullover",
        //                     title: "Pullover",
        //                     link: "/samples/q/gender:men,fabric:flat,cat:pullover",
        //                 },
        //                 {
        //                     name: "bottom",
        //                     title: "Bottoms",
        //                     link: "/samples/q/gender:men,fabric:flat,cat:bottom",
        //                 },
        //             ],
        //         },
        //         {
        //             name: "circular",
        //             title: "Circular",
        //             link: "/samples/q/gender:men,fabric:circular",
        //             class: "sm-rows",
        //             submenu: [
        //                 {
        //                     name: "jacket",
        //                     title: "Jackets",
        //                     link: "/samples/q/gender:men,fabric:circular,cat:jacket",
        //                 },
        //                 {
        //                     name: "pullover",
        //                     title: "Pullover",
        //                     link: "/samples/q/gender:men,fabric:circular,cat:pullover",
        //                 },
        //                 {
        //                     name: "bottom",
        //                     title: "Bottoms",
        //                     link: "/samples/q/gender:men,fabric:circular,cat:bottom",
        //                 },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     name: "accessories",
        //     title: "Accessories",
        //     link: "/samples/q/cat:accessory",
        //     class: "lg-cols",
        // },
        {
            name: "materials",
            title: "Materials",
            link: "/materials/q/",
            class: "lg-cols",
            submenu: [
                {
                    name: "flat",
                    title: "Flat Knit",
                    link: "/materials/q/fabric:flat",
                    class: "sm-rows",
                    submenu: [
                        {
                            name: "sustainable",
                            title: "Sustainable",
                            link: "/materials/q/fabric:flat,cat:sustainable",
                        },
                        {
                            name: "fine_gauge",
                            title: "Fine Gauge",
                            link: "/materials/q/fabric:flat,cat:fine_gauge",
                        },
                        {
                            name: "heavy_gauge",
                            title: "Heavy Gauge",
                            link: "/materials/q/fabric:flat,cat:heavy_gauge",
                        },
                    ],
                },
                {
                    name: "circular",
                    title: "Circular",
                    link: "/materials/q/fabric:circular",
                    class: "sm-rows",
                },
            ],
        },
        {
            name: "accessories",
            title: "Accessories",
            link: "/accessories/q/",
            class: "lg-cols"
        },
        {
            name: "trends",
            title: "Trends",
            link: "/trends/q/",
            class: "lg-cols",
            submenu: [
                {
                    name: "women",
                    title: "Women",
                    link: "/trends/q/cat:women",
                    class: "sm-rows",
                },
                {
                    name: "men",
                    title: "Men",
                    link: "/trends/q/cat:men",
                    class: "sm-rows",
                },
            ],
        },
        {
            name: "innow",
            title: "In Now",
            link: "/in-now/",
            class: "lg-cols red",
        },
        {
            name: "about_us",
            title: "About Us",
            link: "/about",
            class: "sm-rows",
            submenu: [
                {
                    name: "company_info",
                    title: "Company Info",
                    link: "/about",
                },
                {
                    name: "contact",
                    title: "Contact",
                    link: "/contact",
                },
            ],
        },
    ],
};

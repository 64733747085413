import React, { useEffect, useState } from "react";
import "./index.scss";
import { get, post, postForm } from "../../utils";
import Modal from "../../Components/Modal";
import Form, { IOption, FormResultStatus } from "../../Components/FinalForm";
import MaterialSchema from "../../Schemas/material.schema.json";
import Select from "react-select";

interface IMaterialEditProps {
    _id?: string;
    po?: string;
    onClose: Function;
}

export default function MaterialEdit(props: IMaterialEditProps) {
    const [formData, setFormData] = useState<{ [key: string]: any }>({});

    const [contents, setContents] = useState<{ value: string; label: string }[]>([]);
    const [contentLabels, setContentLabels] = useState<{ [key: string]: any }>({});

    const [tags, setTags] = useState<{ value: string; label: string }[]>([]);
    const [tagsLabels, setTagsLabels] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        get("/services/contents").then((res) => {
            if (!res) {
                console.error("获取content出错叻...");
                console.error(res);
                return;
            }
            const contents = [];
            const contentLabels: { [key: string]: any } = {};
            for (const cont of res.data) {
                contents.push({ value: cont.value, label: cont.label_en + " - " + cont.label_cn });
                contentLabels[cont.value] = cont;
            }
            setContents(contents);
            setContentLabels(contentLabels);
        });

        get("/services/tags").then((res) => {
            if (!res) {
                console.error("获取tags出错叻...");
                console.error(res);
                return;
            }
            const tags = [];
            const tagsLabels: { [key: string]: any } = {};
            for (const tag of res.data) {
                tags.push({ value: tag.value, label: tag.label_en + " - " + tag.label_cn });
                tagsLabels[tag.value] = tag;
            }
            setTags(tags);
            setTagsLabels(tagsLabels);
        });
    }, []);

    useEffect(() => {
        if (!props._id) return;
        post("/materials/searchbyid", {
            id: props._id,
        }).then(
            (res) => {
                setFormData(res.data);
            },
            (err) => {
                console.error(err);
            }
        );
    }, [props._id]);

    const getValue = (value: string, store: { [key: string]: any }) => {
        if (store[value]) {
            return {
                value,
                label: store[value].label_en,
            };
        }
        return null;
    };

    let contentSet = new Set<string>();
    if (formData && formData["content"]) {
        for (const content of formData["content"]) {
            contentSet.add(content[0]);
        }
    }

    const renderContentSelector = () => {
        const renderedContents = [];

        if (!formData["content"]) {
            formData["content"] = [];
        }

        for (let i = 0; i < formData["content"].length; i++) {
            const content = formData["content"][i];
            renderedContents.push(
                <div key={"content-" + content[0]} className="content-row">
                    <Select
                        className="input-field-item content-name"
                        classNamePrefix="ifi"
                        value={getValue(content[0], contentLabels)}
                        placeholder="搜索成分..."
                        options={contents}
                        onChange={(value) => {
                            if (value as IOption) {
                                const option = value as IOption;
                                formData["content"][i][0] = option.value;
                                setFormData({ ...formData });
                            }
                        }}
                        isOptionSelected={(option) => {
                            return contentSet.has(option.value);
                        }}
                        hideSelectedOptions
                        formatOptionLabel={(option) => {
                            return (
                                <div className="content-label-container">
                                    <div>
                                        <div className="content-label-en">
                                            {contentLabels[option.value] ? contentLabels[option.value]["label_en"] : option.value}
                                        </div>
                                        <div className="content-label-cn">
                                            {contentLabels[option.value] ? contentLabels[option.value]["label_cn"] : option.value}
                                        </div>
                                    </div>
                                    <div className="content-value">{option.value}</div>
                                </div>
                            );
                        }}
                    ></Select>
                    <input
                        className="content-pres"
                        value={content[1]}
                        placeholder="%"
                        onChange={(e) => {
                            formData["content"][i][1] = e.target.value;
                            setFormData({ ...formData });
                        }}
                    ></input>
                    <button
                        tabIndex={-1}
                        className="circle sm"
                        onClick={() => {
                            formData["content"].splice(i, 1);
                            setFormData({ ...formData });
                        }}
                    >
                        ×
                    </button>
                </div>
            );
        }
        return (
            <div>
                {renderedContents}
                <button
                    onClick={() => {
                        if (!formData["content"]) formData["content"] = [];
                        formData["content"].push(["", null]);
                        setFormData({ ...formData });
                    }}
                >
                    增加成分
                </button>
                <p className="caption">
                    成分不需要考虑排序, 系统会根据比例由高到低排序.
                    <br />* 如果不确定百分比, 比如混纱, 百分比请填0
                    <br />* 如需添加成分种类, 目前请联系Henry, 之后会新增添加种类功能, 谢谢!
                </p>
            </div>
        );
    };

    const getMultiValues = (currentTags: string[], tagsLabels: { [key: string]: any }) => {
        const concatedValues = [];
        for (const tag of currentTags) {
            concatedValues.push(tagsLabels[tag]);
        }
        return concatedValues;
    };

    const renderTagsSelector = () => {
        if (!formData["content"]) {
            formData["content"] = [];
        }

        const currentTags = formData && formData["tags"] ? formData["tags"] : [];
        return (
            <div>
                <Select
                    className="input-field-item content-name"
                    classNamePrefix="ifi"
                    isMulti
                    value={getMultiValues(currentTags, tagsLabels)}
                    placeholder="搜索关键词..."
                    options={tags}
                    onChange={(values) => {
                        const computedTags = [];
                        if (values) {
                            for (const { value } of values) {
                                computedTags.push(value);
                            }
                        }
                        formData["tags"] = computedTags;
                        setFormData({ ...formData });
                    }}
                    formatOptionLabel={(option) => {
                        return (
                            <div className="content-label-container">
                                <div>
                                    <div className="content-label-en">{tagsLabels[option.value] ? tagsLabels[option.value]["label_en"] : option.value}</div>
                                    <div className="content-label-cn">{tagsLabels[option.value] ? tagsLabels[option.value]["label_cn"] : option.value}</div>
                                </div>
                                <div className="content-value">{option.value}</div>
                            </div>
                        );
                    }}
                ></Select>
                <p className="caption">
                    注意, 关键词需要考虑排序, 排序顺序与显示顺序一致
                    <br />* 如需添加关键词, 目前请联系Henry, 之后会新增添加关键词功能, 谢谢!
                </p>
            </div>
        );
    };

    const submit = async () => {
        const form = new FormData();
        form.append("data", JSON.stringify(formData));
        if (formData["$files"]) {
            for (const field of Object.keys(formData["$files"])) {
                for (const file of formData["$files"][field]) {
                    form.append("file__" + field + "__" + file.name, file, file.name);
                }
            }
        }
        let requestRes = {};
        await postForm("/materials/upload", form).then(
            (res) => {
                requestRes = {
                    status: FormResultStatus.SUCCESS,
                    message: "更新好咯, 刷新以后就看见新的内容啦!",
                };
            },
            (err) => {
                console.error(err);
                requestRes = {
                    status: FormResultStatus.FAILURE,
                    message: "不好意思出了点问题..." + JSON.stringify(err.response.data),
                };
            }
        );
        return requestRes;
    };

    const deleteSample = async () => {
        let requestRes = {};
        const confirmed = window.confirm("确定要删除吗? 删除后不能恢复喔. 需要拿原数据重新添加. ");
        if (confirmed) {
            await post("/materials/delete", {
                _id: props._id,
            }).then(
                (res) => {
                    props.onClose();
                    window.alert("好了, 删掉了! 刷新以后就没了.");
                },
                (err) => {
                    console.error(err);
                    requestRes = {
                        status: FormResultStatus.FAILURE,
                        message: "不好意思出了点问题...没法删除! " + JSON.stringify(err),
                    };
                }
            );
        }
        return requestRes;
    };

    return (
        <Modal id={props.po || "new-material"} backdrop titleBar={props.po ? `编辑布料` : "新的布料"} close={() => props.onClose()} style={{ width: 1200 }}>
            <Form
                id={props.po || "new-material"}
                schema={MaterialSchema}
                context={[formData, setFormData]}
                actions={{ submit, delete: deleteSample }}
                options={{
                    fabric: [
                        { value: "flat", label: "针织 Flat Knit" },
                        { value: "circular", label: "圆机 Circular Knit" },
                    ],
                    cat: [
                        { value: "sustainable", label: "Sustainable" },
                        { value: "fine_gauge", label: "细针 Fine Gauge" },
                        { value: "heavy_gauge", label: "粗针 Heavy Gauge" },
                    ],
                }}
                customized={{
                    content: () => renderContentSelector(),
                    tags: () => renderTagsSelector(),
                }}
            />
        </Modal>
    );
}

import React from "react";
import "./index.scss";
import { Section } from "../../Components/Section";
import { CarousolSlide } from "../../Components/Carousol";
import Template from "../../Template";
import LoginModal from "../../Components/Header/LoginModal";
import Widget from "../../Components/Widgets";
import { useSelector } from "react-redux";
import { RootState } from "../../Reducers";

export default function FrontPageMain() {
    const account = useSelector((state: RootState) => state.account);

    // Render Guest Homepage
    // if (!account.user && !localStorage.getItem("accessToken")) {
    //     return (
    //         <Template hideDummyHeader>
    //             <Section full>
    //                 <CarousolSlide id="guest-page-slide" background={"/assets/images/carousol.jpg"}>
    //                     <div id="frontpage-login-container" style={{ position: "absolute" }}>
    //                         <LoginModal id="full-login-modal" close={() => {}} inline />
    //                     </div>
    //                 </CarousolSlide>
    //             </Section>
    //         </Template>
    //     );
    // }

    return (
        <Template>
            <Section full>
                <Widget name="frontpage_carousol" />
            </Section>
        </Template>
    );
}

import React from "react";
import LoadingImage from "./loading.svg";
import "./index.scss";

export default function Loading() {
    return (
        <div className="loading h-flex ac jc">
            <div>
                <img className="loading-img" src={LoadingImage} alt="loading spinner" />
            </div>
            Loading
        </div>
    );
}

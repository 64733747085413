export interface ActionPayload {
    user?: any;
    error?: ActionError;
}

export interface ActionError {
    statusCode: number;
    clientMessage?: string;
    Stack?: string;
}

export interface Action<T = any> {
    type: any;
    payload: T;
}

// Types of actions

// Login Action
export const LOGIN_ACTION = "LOGIN_ACTION";
export interface LoginActionRequest {
    username: string;
    password: string;
}

export const GET_LOGIN_ACTION = "GET_LOGIN_ACTION";

export const LOGOUT_ACTION = "LOGOUT_ACTION";

// Save Unsave Item Action
export const SAVE_ITEM_ACTION = "SAVE_ITEM_ACTION";
export interface SaveItemActionRequest {
    favoriteActions: FavoriteAction[];
}
export interface SaveItemActionResponse {
    [resourceType: string]: string[];
}
export interface FavoriteAction {
    resourceType: string;
    id: string;
    setFavorite: boolean;
}

// System Action
// export const SYSTEM_ACTION = "SYSTEM_ACTION";
// export interface SystemActionMessage extends ActionPayload {
//     message: string;
// }
// export interface SystemAction extends Action<typeof SYSTEM_ACTION, SystemActionMessage> {}
// export interface SystemState {}
